import { Component } from '@angular/core';
import { CartHeaderIconBaseComponent } from '@core/base-frontend/src/cart-base/cart-header-icon.component';

@Component({
  selector: 'cart-header-icon',
  templateUrl: './cart-header-icon.component.html',
  styleUrls: ['./cart-header-icon.component.scss']
})
export class CartHeaderIconComponent extends CartHeaderIconBaseComponent {

}

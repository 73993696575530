import { Component } from '@angular/core';
import { OrderBaseComponent } from '@core/base-frontend/src/order-base/order-base.component';

@Component({
  selector: 'order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent extends OrderBaseComponent {

}
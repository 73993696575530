import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { FieldArrayType } from '@ngx-formly/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'core-admin-category-repeat-type',
  templateUrl: './category-repeat-type.component.html',
  styleUrls: ['./category-repeat-type.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryRepeatTypeComponent extends FieldArrayType implements OnInit {
  selectedOption: any;
  idActive = 0;
  @ViewChild('categoryTabs', { static: true }) categoryTabs: TabsetComponent;
  @ViewChild('btnSubmit', { static: true }) btnSubmit: ElementRef<HTMLElement>;
  modalRef: BsModalRef;

  HtmlRegEx = /<(h1|h2|h3|h4|h5|h6|span|p|a).*?>([\s\S\n]*?)<\/(?:h1|h2|h3|h4|h5|h6|span|p|a)>|placeholder="([\s\S\n]*?)"|value="([\s\S\n]*?)"/g;
  HtmlInput = '';
  HtmlOutput: any;
  DBOutput = [];
  MappingItems = [];
  Contents = [];
  JSONDB: string;
  ListInfos: any;




  constructor(
    // public cdf: ChangeDetectorRef,
    public globalService: GlobalService,
    private modalService: BsModalService
  ) {
    super();
  }
  ngOnInit() {
    // const sub = this.formControl.valueChanges.subscribe(a => {
    //   sub.unsubscribe();
    //   this.cdf.markForCheck();
    //   console.log('CategoryRepeatTypeComponent');

    // })
  }
  removeTabHandler(id) {
    const a = this.globalService.showConfirmDelete()
      .subscribe(result => {
        a.unsubscribe();
        if (result) {
          this.remove(id);
          if (id > 0) {
            this.categoryTabs.tabs[id - 1].active = true;
          }
          // this.btnSubmit.nativeElement.click();
        }
      });
  }

  addnewcategoryTabs() {
    this.add();
    this.idActive = this.categoryTabs.tabs.length;
  }

  getInfosFromHTML(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-xl modal-dialog-centered' });
  }




  extractHtml() {
    let m;
    let i = 0;
    this.MappingItems = [];
    this.Contents = [];

    while ((m = this.HtmlRegEx.exec(this.HtmlInput)) !== null) {
      if (m.index === this.HtmlRegEx.lastIndex) {
        this.HtmlRegEx.lastIndex++;
      }
      i++;
      let s = '';

      if (m[3]) {
        this.Contents.push({ html: m[3], tag: '', content: m[3], type: 'placeholder' });
        s = m[3];
      }
      else if (m[4]) {
        this.Contents.push({ html: m[4], tag: '', content: m[4], type: 'value' });
        s = m[4];
      }
      else {
        this.Contents.push({ html: m[0], tag: m[1], content: m[2], type: 'tag' });
        s = m[2];
      }
      this.MappingItems.push(this.TrimM(s));
    }
  }

  TrimM(s) {
    if (s.length > 30) {
      s = s.substring(0, 30);
    }
    return s;
  }


  GenDB() {

    const gCode = this.form.value.group_code;
    this.HtmlOutput = this.HtmlInput;
    for (let j = 0; j < this.MappingItems.length; j++) {
      const title = this.MappingItems[j];
      const url = this.globalService.slugify(title);
      const item = this.Contents[j];

      this.DBOutput.push({
        "title": title,
        "url": url,
        "values": [
          {
            "display": item.content,
            "type": "display1"
          }
        ]
      });

      this.JSONDB = JSON.stringify(this.DBOutput);
      let nn = '';
      if (this.Contents[j].type === 'tag') {
        nn = `<${this.Contents[j].tag}>{{localCacheService.GroupInfo$ | async | filterGroupInfo: '${gCode}': '${url}'}}</${this.Contents[j].tag}>`;
      }
      else {
        nn = `{{localCacheService.GroupInfo$ | async | filterGroupInfo: '${gCode}': '${url}'}}`;
      }
      this.HtmlOutput = this.HtmlOutput.replace(this.Contents[j].html, nn);
    }


    Object.assign(this.model, this.DBOutput);
    this.model = [...this.model];
  }

  trackByIdx(index: number, obj: any): any {
    return index;
  }

  removeItem(index) {
    this.Contents.splice(index, 1);
    this.MappingItems.splice(index, 1);
  }

}

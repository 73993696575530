import { Injectable } from '@angular/core';

@Injectable()
export class CartService {

  constructor() { }

  public clearAll(): void {
    this.clearCart();
    this.clearOrder();
  }

  public clearCart(): void {
    localStorage.setItem('cart', null);
  }

  public cartHasItems(): boolean {
    return (localStorage.getItem('cart') !== null);
  }

  public cartGetItems(): any {
    if (this.cartHasItems()) {
      let cart = localStorage.getItem('cart');
      cart = JSON.parse(cart);
      return cart;
    }
    return null
  }

  public clearOrder(): void {
    localStorage.setItem('order', null);
  }

  public cartUpdateItems(items: any): void {
    const itemStr = JSON.stringify(items);
    localStorage.setItem('cart', itemStr);
  }

  public orderHasItems(): boolean {
    return (localStorage.getItem('order') !== null && localStorage.getItem('order') !== 'null');
  }

  public orderHas(key: string): boolean {
    if (this.orderHasItems() && key) {
      let order = localStorage.getItem('order');
      order = JSON.parse(order);
      return (order[key] !== null)
    }
    return false
  }

  public orderGetItems(): any {
    if (this.orderHasItems()) {
      let order = localStorage.getItem('order');
      order = JSON.parse(order);
      return order;
    }
    return null;
  }

  public orderUpdateItems(items: any): void {
    const itemStr = JSON.stringify(items);
    localStorage.setItem('order', itemStr);
  }

}

import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire//auth';
import { AngularFireDatabase } from '@angular/fire//database';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CartService } from '@core/utils/src/services/common/cart.service';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/Observable';
declare var $: any;

@Component({
  selector: 'core-cart-header-base',
  template: ''
})
export class CartHeaderBaseComponent {
  globalCart: any;
  cartArray: any;
  cartTotal: Number;
  user: Observable<firebase.User>;
  currentShopper: any;
  review: boolean;

  constructor(
    public db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    public router: Router,
    public globalService: GlobalService,
    public localCacheService: LocalCacheService,
    private toastr: ToastrService,
    public localCart: CartService,
    private title: Title,
    private meta: Meta
  ) {
    this.user = afAuth.authState;
    this.cartArray = [];
    this.cartTotal = 0;
    this.review = false;

    globalService.cart.subscribe((cart) => {
      this.cartArray = [];
      this.cartTotal = 0;
      this.globalCart = cart;
      if (this.globalCart) {
        this.cartArray = (<any>Object).values(this.globalCart);
        for (let i = 0; i < this.cartArray.length; i++) {
          this.cartTotal += this.cartArray[i].total;
        }
      }
    });
  }

  GoToCartList() {
    this.router.navigateByUrl("/order");
    this.hideHeaderCart()
      ;
  }
  GoToCheckout() {
    this.router.navigateByUrl("/order");
    this.hideHeaderCart();
  }

  hideHeaderCart() {
    $('.cart-hover').css("opacity", "0");
    $('.cart-hover').css("visibility", "hidden");
    setTimeout(() => {
      $('.cart-hover').removeAttr("style");
    }, 1000);

  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SectionBaseComponent } from '@core/base-frontend/src/home-base/section-base.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SectionComponent } from './section.component';

@NgModule({
  declarations: [SectionComponent, SectionBaseComponent],
  imports: [
    CommonModule,
    RouterModule,
    PopoverModule.forRoot()
  ],
  exports: [SectionComponent, SectionBaseComponent]
})
export class SectionModule { }

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire//auth';
import { AngularFireDatabase } from '@angular/fire//database';
import { ActivatedRoute, Router } from '@angular/router';
import { FrontBaseComponent } from '@core/base-frontend/src/home-base/front-base.component';
import { CartService } from '@core/utils/src/services/common/cart.service';
import { DynamicScriptAndStyleLoaderService } from '@core/utils/src/services/common/dynamic-loader.service';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import { NgSelectConfig } from '@ng-select/ng-select';

declare var $: any;

@Component({
  selector: 'app-front',
  templateUrl: './front.component.html',
  styleUrls: ['./front.component.scss']
})


export class FrontComponent extends FrontBaseComponent implements OnInit, AfterViewInit {
  currentUrl: string;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    public globalService: GlobalService,
    public localCart: CartService,
    public dynamicScriptLoader: DynamicScriptAndStyleLoaderService,
    public localCacheService: LocalCacheService,
    public config: NgSelectConfig
  ) {

    super(router, route, db, afAuth, globalService, localCart, dynamicScriptLoader, localCacheService, config);

  }

  ngOnInit() {

    this.currentUrl = this.router.url;

    this.loadStyles();
    this.loadScripts();


    const a = setInterval(() => {
      if (typeof $ !== 'undefined' && !!$.prototype.magnificPopup && !!$.prototype.scrollTop && !!$.prototype.owlCarousel) {
        clearInterval(a);
        /* =================================
         LOADER
         =================================== */
        $(".loader").delay(400).fadeOut();
        $(".animationload").delay(400).fadeOut("fast");

        const bgi = $("[data-background]");
        // tslint:disable-next-line: no-unused-expression
        bgi.length > 0 && bgi.each(function () {
          const e = $(this),
            t = e.attr('data-background');
          e.css({ 'background-image': 'url(' + t + ')' });
        });

        const progressBar = $('.progress-bar');
        // tslint:disable-next-line: no-unused-expression
        progressBar.length > 0 && progressBar.each(function () {
          const e = $(this),
            t = e.attr('aria-valuenow');
          e.css({ 'width': t + '%' });
        });

        /* =================================
        NAVBAR
        =================================== */
        let top = $(document).scrollTop();
        const batas = 200;
        const navbar = $('.navbar-main');
        const navbarnav = $('.navbar-nav');
        const header = $('.header');


        if (top > batas) {
          navbar.addClass('stiky');
          // navbarnav.addClass('ml-auto');
        }
        $(window).scroll(function () {
          top = $(document).scrollTop();


          if (top > batas) {
            navbar.addClass('stiky');
          } else {
            navbar.removeClass('stiky');
            if (header.hasClass('header-2')) {
              navbarnav.removeClass('ml-auto');
            }
            if (header.hasClass('header-5')) {
              navbarnav.removeClass('ml-auto');
            }
          }

        });

        /* =================================
        BANNER ROTATOR IMAGE
        =================================== */
        const slides = $("#oc-fullslider"),
          b = slides.find('.owl-slide');
        b.each(function () {
          const e = $(this),
            ocImg = e.find('img').attr('src');
          e.css({ 'background-image': 'url(' + ocImg + ')' });
        });

        slides.owlCarousel({
          items: 1,
          autoplay: true,
          autoplayTimeout: 5000,
          autoplayHoverPause: true,
          loop: true,
          animateIn: 'fadeIn',
          animateOut: 'rollOut',
          pagination: false,
          nav: true,
          navText: ["<span class='fa fa-chevron-left'></span>", "<span class='fa fa-chevron-right'></span>"],
          dots: true,
        });

        /* =================================
        BACK TO TOP
        =================================== */
        // browser window scroll (in pixels) after which the "back to top" link is shown
        const offset = 300,
          //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
          offset_opacity = 1200,
          //duration of the top scrolling animation (in ms)
          scroll_top_duration = 700,
          //grab the "back to top" link
          $back_to_top = $('.cd-top');

        //hide or show the "back to top" link
        $(window).scroll(function () {
          ($(this).scrollTop() > offset) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
          if ($(this).scrollTop() > offset_opacity) {
            $back_to_top.addClass('cd-fade-out');
          }
        });

        //smooth scroll to top
        $back_to_top.on('click', function (event) {
          event.preventDefault();
          $('body,html').animate({
            scrollTop: 0,
          }, scroll_top_duration
          );
        });


        /* =================================
        OWL
        =================================== */

        const caro = $("#caro");
        caro.owlCarousel({
          items: 1,
          autoplay: true,
          autoplayTimeout: 5000,
          autoplayHoverPause: true,
          loop: true,
        });
        const caro2 = $("#caro-2");
        caro2.owlCarousel({
          autoplay: true,
          margin: 30,
          autoplayTimeout: 5000,
          autoplayHoverPause: true,
          items: 3,
          dots: true,
          loop: true,
          responsive: {
            0: {
              items: 1
            },
            768: {
              items: 2
            },
            1000: {
              items: 3
            }
          }
        });
        const testimony = $("#testimonial");
        testimony.owlCarousel({
          items: 1,
          autoplay: true,
          autoplayTimeout: 5000,
          autoplayHoverPause: true,
          loop: true,
          animateIn: 'fadeIn',
          animateOut: 'fadeOut',
        });

        const testimony2 = $("#owl-testimony2");
        testimony2.owlCarousel({
          items: 1,
          autoplay: true,
          autoplayTimeout: 5000,
          autoplayHoverPause: true,
          navText: ["<span class='fa fa-chevron-left'></span>", "<span class='fa fa-chevron-right'></span>"],
          dots: true,
          loop: true
        });
        const caro_2_colm = $("#caro_2_colm");
        caro_2_colm.owlCarousel({
          autoplay: true,
          margin: 30,
          autoplayTimeout: 5000,
          autoplayHoverPause: true,
          dots: true,
          loop: true,
          nav: false,
          navText: ["<span class='fa fa-chevron-left'></span>", "<span class='fa fa-chevron-right'></span>"],
          items: 2,
          responsive: {
            0: {
              items: 1
            },
            768: {
              items: 2
            },
            1000: {
              items: 2
            }
          }
        });
        const caro4 = $("#caro-4");
        caro4.owlCarousel({
          autoplay: true,
          autoplayTimeout: 5000,
          autoplayHoverPause: true,
          items: 4,
          dots: true,
          loop: true,
          responsive: {
            0: {
              items: 1
            },
            768: {
              items: 2
            },
            1000: {
              items: 4
            }
          }
        });

        /* =================================
        MAGNIFIC POPUP
        =================================== */
        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
          disableOn: 700,
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: false,

          fixedContentPos: false
        });

        $('.popup-gallery').magnificPopup({
          delegate: 'a',
          type: 'image',
          tLoading: 'Loading image #%curr%...',
          mainClass: 'mfp-img-mobile',
          gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1]
          },
          image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function (item) {
              return item.el.attr('title') + '';
            }
          }
        });


      }
    }, 800);

  }

  private loadScripts() {
    this.dynamicScriptLoader.loadAllScripts('assets/js/vendor/jquery-2.2.4.min.js').then(data => {
      this.dynamicScriptLoader.loadAllScripts('https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js')
    }).then(data => {
      this.dynamicScriptLoader.loadAllScripts(
        'assets/js/vendor/owl.carousel.js',
        'assets/js/vendor/jquery.magnific-popup.min.js',
        'assets/js/vendor/validator.min.js',
        'assets/js/vendor/form-scripts.js',
      );
    })
      .catch(error => console.log(error));
  }

  private loadStyles() {
    this.dynamicScriptLoader
      .loadAllStyles(
        'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css',
        'assets/css/vendor/font-awesome.min.css',
        'https://fonts.googleapis.com/css?family=Nunito:400,700,900&display=swap&subset=vietnamese',
        'assets/css/vendor/owl.carousel.min.css',
        'assets/css/vendor/owl.theme.default.min.css',
        'assets/css/vendor/magnific-popup.css',
        'assets/css/vendor/animate.min.css',
        'assets/css/style.css'
      )
      .then(data => { })
      .catch(error => console.log(error));
  }

  ngAfterViewInit() {

  }

}

import { Component, OnInit } from '@angular/core';
import { DestroyableComponent } from '@core/utils/src/components/destroyable/destroyable.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocalCacheService } from '../../services/common/local-cache.service';
import { UploadFileService } from '../upload-file.service';


@Component({
  selector: 'core-admin-grid-images',
  templateUrl: './grid-images.component.html',
  styleUrls: ['./grid-images.component.scss']
})
export class GridImagesComponent extends DestroyableComponent implements OnInit {

  constructor(
    public uploadService: UploadFileService,
    public globalCache: LocalCacheService,
    public modalService: BsModalService,
    private toastr: ToastrService,
  ) {
    super();
  }
  public onClose: Subject<string>;
  window: any;
  fileUploads: any[];
  sortOptions: SelectItem[] = [
    { label: 'Ngày đăng mới nhất', value: '!date' },
    { label: 'Ngày đăng cũ nhất', value: 'date' }
  ];
  sortOrder: number = -1;
  sortField: string;
  cols: any[] = [
    { field: 'url', header: 'Ảnh (Mở rộng cột để phóng to)', sortable: false, filterable: false, width: '255px' },
    { field: 'name', header: 'Tên ảnh', sortable: true, filterable: true, width: '40%' },
    { field: 'date', header: 'Ngày đăng', sortable: true, filterable: true, width: '135px' }
  ];


  ngOnInit() {
    this.onClose = new Subject();
    this.window = (<any>window);

    this.globalCache.getLocalDB('images')
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(images => {
        // this.fileUploads = images.sort((a: any, b: any) => Number(new Date(a.date)) - Number(new Date(b.date)));

        this.fileUploads = images.sort((a: any, b: any) => {
          // if both images have a date property, compare them normally
          if (a.date && b.date) {
            return Number(new Date(b.date)) - Number(new Date(a.date));
          }
          // if one image has a date property and the other does not, decide which one comes first
          else if (a.date && !b.date) {
            return -1; // or 1 if you want the image without a date to come first
          }
          else if (!a.date && b.date) {
            return 1; // or -1 if you want the image without a date to come first
          }
          // if both images do not have a date property, do not change their order
          else {
            return 0;
          }
        });
        // setTimeout(() => {
        //   this.sortField = 'date';
        //   this.sortOrder = -1;
        // }, 100);
      });
  }


  copyImage(e) {
    this.toastr.success('Đã copy đường dẫn ảnh "' + e.name + '" vào ClipBoard.');
    const el: any = document.createElement('textarea');
    el.value = e.url;
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  onSortChange(event) {
    const value = event.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }
  emitSelectImage(event) {
    this.toastr.success('Đã chọn ảnh "' + event.name + '" .');
    this.onClose.next(event.url);
  }
}

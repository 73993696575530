import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime)

@Pipe({ name: 'getyear' })
export class GetYearPipe implements PipeTransform {
  transform(value: any): string {
    return dayjs(value).format('YYYY');
  }
}
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire//auth';
import { AngularFireDatabase } from '@angular/fire//database';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsBaseComponent } from '@core/base-frontend/src/product-base/products-base.component';
import { CartService } from '@core/utils/src/services/common/cart.service';
import { DynamicScriptAndStyleLoaderService } from '@core/utils/src/services/common/dynamic-loader.service';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent extends ProductsBaseComponent implements OnInit {

  constructor(
    public cd: ChangeDetectorRef,
    public db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    public globalService: GlobalService,
    public localCart: CartService,
    public router: Router,
    public route: ActivatedRoute,
    public activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public title: Title,
    public meta: Meta,
    public dynamicScriptLoader: DynamicScriptAndStyleLoaderService,
    public localCacheService: LocalCacheService
  ) {
    super(cd, db, afAuth, globalService, localCart, router, route, activatedRoute, toastr, title, meta, dynamicScriptLoader, localCacheService);
  }

  ngOnInit() {
    const a = setInterval(() => {
      if (typeof $ !== 'undefined') {
        clearInterval(a);
        $('.products-categories').addClass('categories-grid');
        $('.click-grid').on('click', function (e) {
          $('.products-categories').addClass('categories-grid');
          $('.products-categories').removeClass('categories-list');
          $('.click-grid').addClass('color-active');
          $('.click-list').removeClass('color-active');
          e.stopPropagation();
        });
        $('.click-list').on('click', function (e) {
          $('.products-categories').addClass('categories-list');
          $('.products-categories').removeClass('categories-grid');
          $('.click-list').addClass('color-active');
          $('.click-grid').removeClass('color-active');
          e.stopPropagation();
        });
      }
    });
  }

}
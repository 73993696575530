import { Component } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire//database';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'core-section-base',
  template: ''
})
export class SectionBaseComponent {

  constructor(
    public db: AngularFireDatabase,
    public toastr: ToastrService,
    public localCacheService: LocalCacheService,
    public globalService: GlobalService) {


  }

}

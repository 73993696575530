import { Component, OnInit } from '@angular/core';
import { DestroyableComponent } from '@core/utils/src/components/destroyable/destroyable.component';
import { LocalCacheService } from '../../services/common/local-cache.service';


@Component({
  selector: 'core-admin-list-upload',
  templateUrl: './list-upload.component.html',
  styleUrls: ['./list-upload.component.scss']
})
export class ListUploadComponent extends DestroyableComponent implements OnInit {
  constructor(
    public globalCache: LocalCacheService
  ) {
    super();
  }

  ngOnInit() {

  }

}

import { Component } from '@angular/core';
import { PostBaseComponent } from '@core/base-frontend/src/blog-base/post-base.component';

@Component({
  selector: 'post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent extends PostBaseComponent {

}

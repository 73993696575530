import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'core-repeat-feature-name',
  templateUrl: './repeat-feature-name.component.html',
  styleUrls: ['./repeat-feature-name.component.scss']
})
export class RepeatFeatureNameComponent extends FieldArrayType implements OnInit {
  idActive = -1;
  @ViewChild('btnSubmit', { static: true }) btnSubmit: ElementRef<HTMLElement>;

  constructor(public globalService: GlobalService) {
    super();
  }
  ngOnInit() {
  }

  addnewHandler() {
    this.add();
    this.idActive = this.model.length - 1;
  }

  removeHandler(id) {
    const a = this.globalService.showConfirmDelete()
      .subscribe(result => {
        a.unsubscribe();
        if (result) {
          this.remove(id);
          this.btnSubmit.nativeElement.click();
        }
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'core-admin-time-picker-type',
  templateUrl: './time-picker-type.component.html',
  styleUrls: ['./time-picker-type.component.scss']
})
export class TimePickerTypeComponent extends FieldType implements OnInit {

  constructor() { super(); }

  valid = true;

  isValid(event: boolean): void {
    this.valid = event;
  }

  ngOnInit() {
    this.formControl.valueChanges.subscribe(val => {
      if (val && val as Date) {
        this.model[this.field.key as any] = (val as Date).toISOString();
      }
    })
  }

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxTinymceModule } from 'ngx-tinymce';
import { TreeFilterModule } from '../shared/tree-filter/tree-filter.module';
import { UploadImageModule } from '../upload-image/upload-image.module';
import { CategoryRepeatTypeComponent } from './category-repeat-type/category-repeat-type.component';
import { CkEditorTypeComponent } from './ck-editor-type/ck-editor-type.component';
import { DevexTreeViewFormlyTypeComponent } from './devex-tree-view-formly-type/devex-tree-view-formly-type.component';
import { FormlyHorizontalWrapperComponent } from './field-horizontal-wrapper.component';
import { FormlyFieldWrapperComponent } from './field-wrapper.component';
import { GroupRepeatTypeComponent } from './group-repeat-type/group-repeat-type.component';
import { GroupFieldType } from './group.type';
import { ImageTypeComponent } from './image-type/image-type.component';
import { RemoveWrapperDirective } from './remove-wrapper.directive';
import { RepeatDayTypeComponent } from './repeat-day-type/repeat-day-type.component';
import { RepeatFeatureNameComponent } from './repeat-feature-name/repeat-feature-name.component';
import { RepeatFeatureOptionsComponent } from './repeat-feature-options/repeat-feature-options.component';
import { RepeatTimeTypeComponent } from './repeat-time-type/repeat-time-type.component';
import { RepeatTypeComponent } from './repeat-type/repeat-type.component';
import { FormlyTabWrapper } from './tab.wrapper';
import { FormlyTabsetWrapper } from './tabset.wrapper';
import { TimePickerTypeComponent } from './time-picker-type/time-picker-type.component';
import { TinyMceTypeComponent } from './tinymce-editor-type/tinymce-editor-type.component';

export function requireValidationMessage(err, field) {
  return `"${field.templateOptions.label}"` + ' bắt buộc phải nhập';
}


const compo = [
  RepeatTypeComponent,
  TimePickerTypeComponent,
  CkEditorTypeComponent,
  FormlyFieldWrapperComponent,
  RemoveWrapperDirective,
  RepeatDayTypeComponent,
  RepeatTimeTypeComponent,
  CategoryRepeatTypeComponent,
  GroupRepeatTypeComponent,
  RepeatFeatureNameComponent,
  RepeatFeatureOptionsComponent,
  DevexTreeViewFormlyTypeComponent,
  FormlyHorizontalWrapperComponent,
  FormlyTabWrapper,
  FormlyTabsetWrapper,
  GroupFieldType,
  TinyMceTypeComponent
]



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      extras: { checkExpressionOn: 'modelChange' },
      validationMessages: [
        { name: 'required', message: requireValidationMessage }
      ],
      wrappers: [
        { name: 'form-field', component: FormlyFieldWrapperComponent },
        { name: 'formly-horizontal-wrapper', component: FormlyHorizontalWrapperComponent },
        { name: 'tab', component: FormlyTabWrapper },
        { name: 'tabset', component: FormlyTabsetWrapper }
      ],
      types: [
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'repeatday', component: RepeatDayTypeComponent },
        { name: 'repeattime', component: RepeatTimeTypeComponent },
        { name: 'repeat-feature-name', component: RepeatFeatureNameComponent },
        { name: 'repeat-feature-options', component: RepeatFeatureOptionsComponent },
        { name: 'treeview', component: DevexTreeViewFormlyTypeComponent, wrappers: ['form-field'] },
        { name: 'input-image', component: ImageTypeComponent, wrappers: ['form-field'] },
        {
          name: 'timepicker',
          component: TimePickerTypeComponent,
          wrappers: ['form-field']
        },
        {
          name: 'ckeditor',
          component: CkEditorTypeComponent,
          wrappers: ['form-field']
        },
        {
          name: 'tinymce',
          component: TinyMceTypeComponent,
          wrappers: ['form-field']
        },
        { name: 'repeatcategory', component: CategoryRepeatTypeComponent },
        { name: 'repeat-group', component: GroupRepeatTypeComponent },
        { name: 'formly-group', component: GroupFieldType },
      ]
    }),
    FormlyBootstrapModule,
    TreeFilterModule,
    AccordionModule.forRoot(),
    TimepickerModule.forRoot(),
    CKEditorModule,
    TabsModule.forRoot(),
    UploadImageModule,
    NgxTinymceModule.forRoot({
      baseURL: '//cdnjs.cloudflare.com/ajax/libs/tinymce/5.5.1/',
      config: {
        height: 350,
        plugins: 'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image imagetools link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern',
        toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | fullscreen | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
        image_advtab: true,
        imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
        content_css: [
          '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i'
        ]
      }
    })
  ],
  declarations: [
    ...compo,
    ImageTypeComponent
  ],
  exports: [
    ...compo,
    CommonModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyBootstrapModule,
    CKEditorModule,
    TabsModule,
    TimepickerModule,
    AccordionModule,
    TreeFilterModule,
    UploadImageModule
  ]
})
export class FormlyTypeModule { }

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'core-facebook-posts',
  templateUrl: './facebook-posts.component.html',
  styleUrls: ['./facebook-posts.component.scss']
})
export class FacebookPostComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

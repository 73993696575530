import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { FieldArrayType } from '@ngx-formly/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'core-admin-group-repeat-type',
  templateUrl: './group-repeat-type.component.html',
  styleUrls: ['./group-repeat-type.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupRepeatTypeComponent extends FieldArrayType implements OnInit {
  selectedOption: any;
  idActive = 0;
  @ViewChild('groupTabs', { static: true }) groupTabs: TabsetComponent;
  @ViewChild('btnSubmitGroup', { static: true }) btnSubmitGroup: ElementRef<HTMLElement>;
  constructor(
    // public cdf: ChangeDetectorRef,
    public globalService: GlobalService,
  ) {
    super();
  }

  ngOnInit() {
    // const sub = this.formControl.valueChanges.subscribe(a => {
    //   sub.unsubscribe();
    //   this.cdf.markForCheck();
    //   console.log('GroupRepeatTypeComponent');
    // });
  }
  removeTabHandler(id) {
    const a = this.globalService.showConfirmDelete()
      .subscribe(result => {
        a.unsubscribe();
        if (result) {
          this.remove(id);
          if (id > 0) {
            this.groupTabs.tabs[id - 1].active = true;
          }
          this.btnSubmitGroup.nativeElement.click();
        }
      });
  }

  addnewgroupTabs() {
    this.add();
    this.idActive = this.groupTabs.tabs.length;
  }

}

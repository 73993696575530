import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire//database';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { DestroyableComponent } from '@core/utils/src/components/destroyable/destroyable.component';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'core-page-base',
  template: ''
})
export class PageBaseComponent extends DestroyableComponent implements OnInit {
  categories: any = [];
  idActive = 0;
  categoriesCompanyInfo: any;
  categoriesChinhSach: any;

  constructor(

    public db: AngularFireDatabase,
    public localCacheService: LocalCacheService,
    public route: ActivatedRoute,
    public title: Title,
    public meta: Meta,
  ) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.localCacheService.getLocalDB('pages').subscribe(items => {
        if (items && items.length > 0) {


          this.categories = Object.keys(items[0]).map(function (key) {
            return { ...items[0][key] };
          });
          this.categoriesCompanyInfo = this.categories.filter(val => val.type === 'thongtincongty').sort((a, b) => (a.order > b.order) ? 1 : -1);
          this.categoriesChinhSach = this.categories.filter(val => val.type === 'chinhsachhotro').sort((a, b) => (a.order > b.order) ? 1 : -1);

          this.idActive = this.categories.find(a => a.url === params.url);
          if (this.idActive) {
            this.localCacheService.GroupInfo$
              .pipe(takeUntil(this.unsubscribeAll))
              .subscribe(list => {
                this.title.setTitle((this.idActive as any).title + ' | ' + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_NGAN_GON) + '');

                this.meta.updateTag({ content: (this.idActive as any).title + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_DAY_DU) }, "name='description'");
              });
          }

          setTimeout(() => {
            document.querySelectorAll('oembed[url]').forEach(element => {
              const anchor = document.createElement('a');
              anchor.setAttribute('href', element.getAttribute('url'));
              anchor.className = 'embedly-card';
              element.appendChild(anchor);
            });

          }, 1000);
        }
      });
    });
  };

}

import { Component, OnInit } from '@angular/core';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';

@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(public localCacheService: LocalCacheService) { }

  ngOnInit() {

  }


}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';

export class RepeatType {
  name: string;
  templateOptions: { label?: string };
  fieldGroup: FormlyFieldConfig[];
}

export interface RepeatFieldConfig extends FormlyFieldConfig {
  types: RepeatType[];
  defaultTypes: string[];
}

@Component({
  selector: 'core-repeat-feature-options',
  templateUrl: './repeat-feature-options.component.html',
  styleUrls: ['./repeat-feature-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RepeatFeatureOptionsComponent extends FieldArrayType<RepeatFieldConfig> implements OnInit {

  constructor(
    // public cdf: ChangeDetectorRef,
  ) { super(); }

  ngOnInit() {
    // const sub = this.formControl.valueChanges.subscribe(a => {
    //   sub.unsubscribe();
    //   this.cdf.markForCheck();
    //   console.log('RepeatFeatureOptionsComponent');
    // })
  }

}

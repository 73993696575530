import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'core-admin-repeat-type',
  templateUrl: './repeat-type.component.html',
  styleUrls: ['./repeat-type.component.scss']
})
export class RepeatTypeComponent extends FieldArrayType implements OnInit {

  constructor() {
    super();
  }
  ngOnInit() {
  }

}

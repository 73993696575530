import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DxTreeViewModule } from 'devextreme-angular/ui/tree-view';
import { TreeFilterComponent } from './tree-filter.component';

@NgModule({
  declarations: [
    TreeFilterComponent
  ],
  exports: [
    TreeFilterComponent
  ],
  imports: [
    CommonModule,
    DxTreeViewModule,
  ]
})
export class TreeFilterModule { }

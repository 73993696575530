import { Component, OnInit } from '@angular/core';
import { AppBaseComponent } from '@core/base-frontend/src/home-base/app-base.component';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent extends AppBaseComponent implements OnInit {
  ngOnInit(): void {
    firebase.analytics();
  }


}
import { Component, OnInit } from '@angular/core';
import { FirebaseApp } from '@angular/fire/';
import { AngularFireDatabase } from '@angular/fire//database';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DestroyableComponent } from '@core/utils/src/components/destroyable/destroyable.component';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import { map, takeUntil } from 'rxjs/operators';
declare var FB: any;
declare var $: any;

@Component({
  selector: 'core-post-base',
  template: ''
})
export class PostBaseComponent extends DestroyableComponent implements OnInit {

  public recentposts: any;
  public relatedposts: any;
  public categories: any;
  public post: any;
  public url: string;
  categoryProducts: any[];

  constructor(
    public af: FirebaseApp,
    public db: AngularFireDatabase,
    public route: ActivatedRoute,
    public globalService: GlobalService,
    public localCacheService: LocalCacheService,
    public router: Router,
    private title: Title,
    private meta: Meta
  ) {
    super();
    this.recentposts = this.localCacheService.getLocalDB('posts').pipe(map(a => a.filter(b => b.published === true).slice(0, 3)));
    this.categories = this.localCacheService.getLocalDB('categories');
    this.categoryProducts = this.localCacheService.getRootCategoriesWithAllProducts().map(a => ({ ...a, products: a.products.filter(b => b.showHome === true) }));
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.localCacheService.getLocalDB('posts').pipe(map(a => a.filter(b => b.url === params.url && b.published === true))).subscribe(val => {
        if (val && val.length > 0) {
          this.post = val[0];

          this.relatedposts = this.localCacheService.getLocalDBArray('posts')
            .filter(b =>
              b.published === true &&
              b.type === this.post.type &&
              new Date(b.date) < new Date(this.post.date)
            ).slice(0, 5);

          //Nếu không có bài nào thì lấy thêm bài mới hơn để hiển thị
          if (this.recentposts && this.relatedposts.length < 2) {
            this.relatedposts = this.localCacheService.getLocalDBArray('posts')
              .filter(b =>
                b.published === true &&
                b.type === this.post.type &&
                b.url !== this.post.url
              ).slice(0, 5);
          }


          this.localCacheService.GroupInfo$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(list => {
              this.title.setTitle(this.post.title + ' | ' + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_NGAN_GON) + '');

              this.meta.updateTag({ content: this.post.title + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_DAY_DU) }, "name='description'");
            });

          const doc = new DOMParser().parseFromString(this.post.body.replace(/<[^>]*>/g), 'text/html').documentElement.textContent;
          if (doc) { doc.replace(/undefined/g, '').replace(/\n/g, '') }
          this.meta.updateTag({ content: doc }, "name='description'");

          const a = setInterval(() => {
            if (typeof $ !== 'undefined') {
              clearInterval(a);
              $('div.banner-area.blog-one').css('background', 'url("' + this.post.thumbnail + '") no-repeat scroll center top / cover');
            }
          }, 100);

        } else {
          this.post = {
            title: 'Không tìm thấy bài viết',
            body: ''
          }
        }

        const b = setInterval(() => {
          if (document.getElementById('facebookCommentID') && typeof FB !== 'undefined') {
            clearInterval(b);
            document.getElementById('facebookCommentID').innerHTML = '<div class="fb-comments" data-href="' + document.location + '" data-num-posts="20" data-width="900"></div>';
            FB.XFBML.parse(document.getElementById('facebookCommentID'));
            document.getElementById('fbsharebuttonID').innerHTML = '<div class="fb-share-button" data-href="' + document.location.href + '" data-layout="button_count" data-size="small"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">Chia sẻ</a></div>';
            FB.XFBML.parse(document.getElementById('fbsharebuttonID'));
          }
        }, 100);

      })

    });
  }



  emitChangeValue(value: any) {
    if (value.length > 0) {
      this.router.navigateByUrl('/product', { state: { data: value } });
    }
  }
}

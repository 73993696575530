import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogBaseComponent } from '@core/base-frontend/src/blog-base/blog-base.component';
import { PostBaseComponent } from '@core/base-frontend/src/blog-base/post-base.component';
import { AppBaseComponent } from '@core/base-frontend/src/home-base/app-base.component';
import { UtilsModule } from '@core/utils/src/utils.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { SectionModule } from '../common/section/section.module';
import { BlogComponent } from './blog.component';
import { PostComponent } from './post/post.component';

const routes: Routes = [
  { path: '', component: BlogComponent },
  { path: ':url', component: PostComponent }
]

@NgModule({
  declarations: [
    BlogBaseComponent,
    PostBaseComponent,
    AppBaseComponent,
    BlogComponent,
    PostComponent
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    UtilsModule,
    RouterModule.forChild(routes),
    SectionModule,
  ],
  exports: [RouterModule]
})
export class BlogModule { }

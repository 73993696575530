import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DestroyableComponent } from '../../components/destroyable/destroyable.component';

@Component({
  selector: 'core-tree-filter',
  templateUrl: './tree-filter.component.html',
  styleUrls: ['./tree-filter.component.scss']
})

export class TreeFilterComponent extends DestroyableComponent implements OnInit {

  @ViewChild(DxTreeViewComponent, { static: false }) treeView: DxTreeViewComponent;

  @Output() selectedValues = new EventEmitter<any>();
  @Output() outputTreeView = new EventEmitter<any>();
  @Input() treeDatas;
  @Input() expandAll;
  @Input() enableSearch;
  @Input() checkedIds;
  @Input() outputCheckedItemsDirectly;
  items: any[];
  checkedItems = [];

  public resetChecked = new BehaviorSubject<any>(null);
  DontEmit: boolean;

  constructor(public globalService: GlobalService) {
    super();
  }

  ngOnInit() {

    if (this.globalService.isArray(this.treeDatas)) {
      this.getData(this.treeDatas);
    }
    else {
      this.treeDatas
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe(data => {
          if (data && data.length > 0) {
            this.getData(data);
          }
        });
    }
    setTimeout(() => {
      this.outputTreeView.emit(this.treeView);
    }, 500);

    this.checkAllByIds(this.checkedIds);
    this.resetChecked
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(val => {
        if (val) {
          this.checkAllByIds(val);
        }
      });
  }

  getData(data) {
    this.items = data;
    this.checkedItems = [];
    if (this.checkedIds) {
      this.checkAllByIds(this.checkedIds);
    }

    if (this.expandAll === true) {
      setTimeout(() => {
        if (this.treeView && this.treeView.instance) { this.treeView.instance.expandAll(); }
      }, 500);
    }
  }

  checkAllByIds(checkedIds) {

    if (checkedIds) {
      const cc = setInterval(() => {
        if (this.treeView && this.treeView.instance) {
          clearInterval(cc);
          this.treeView.instance.unselectAll();
          this.DontEmit = true;
          Object.entries(checkedIds).forEach((e: any) => {

            const idx = this.checkedItems.findIndex(x => x.key === e[0])
            const obj = {
              key: e[0],
              name: e[0],
              selected: e[1]
            };

            if (idx !== -1) {
              this.checkedItems[idx] = obj;
            } else {
              this.checkedItems.push(obj);
            }

            this.treeView.instance.selectItem(e[0]);
            if (e[1] === false) {
              this.treeView.instance.unselectItem(e[0]);
            }

            const idx1 = this.items.findIndex(x => x.key === e[0]);
            if (idx1 && this.items[idx1]) {
              this.items[idx1].isSelected = e[1];
            }
          });
          this.DontEmit = false;
        }
      }, 500);
    }
  }

  selectionChanged(e) {
    if (this.DontEmit === false) {
      if (this.outputCheckedItemsDirectly) {

        this.items.forEach((e: any) => {
          if (e.isSelected === false) {
            this.treeView.instance.unselectItem(e.key);
          }
          else if (e.isSelected === undefined) {
            e.isSelected = true;
          }
        });

        this.selectedValues.emit(this.items);
      }
      else {
        this.processNodeAndChildrenRecursively(e.node);
        this.selectedValues.emit(this.checkedItems.map(a => a.key));
      }
    }

  }


  processNode(node) {
    const nodeTemp = {
      key: node.key,
      selected: node.selected,
    }
    let itemIndex = -1;
    this.checkedItems.forEach((item, index) => {
      if (item.key === nodeTemp.key) {
        itemIndex = index;
        return false;
      }
    });
    if (nodeTemp.selected && itemIndex === -1) {
      this.checkedItems.push(nodeTemp);
    } else if (!nodeTemp.selected) {
      this.checkedItems.splice(itemIndex, 1);
    }
  }

  processNodeAndChildrenRecursively(node) {
    if (Object.prototype.toString.call(node) === '[object Array]') {
      node.forEach((child, index) => {
        this.processNode(child);
        this.processNodeAndChildrenRecursively(child.items);
      });
    }
    else {
      this.processNode(node);
      this.processNodeAndChildrenRecursively(node.items);
    }
  }
}

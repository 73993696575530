import { Component, ElementRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire//auth';
import { AngularFireDatabase } from '@angular/fire//database';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductBaseComponent } from '@core/base-frontend/src/product-base/product-base.component';
import { CartService } from '@core/utils/src/services/common/cart.service';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import { Gallery } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent extends ProductBaseComponent {
  constructor(
    public db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    public route: ActivatedRoute,
    public router: Router,
    public globalService: GlobalService,
    public toastr: ToastrService,
    public localCacheService: LocalCacheService,
    public localCart: CartService,
    public title: Title,
    public meta: Meta,
    public sanitizer: DomSanitizer,
    public elRef: ElementRef,
    public gallery: Gallery,
    public lightbox: Lightbox
  ) {
    super(db, afAuth, route, router, globalService, toastr, localCacheService, localCart, title, meta, sanitizer, elRef, gallery, lightbox);

  }

  getRelatedProducts(number?: number) {
    if (this.relatedProducts && this.relatedProducts.products && this.product) {
      return this.relatedProducts.products.filter(a => a.key !== this.product.key).slice(0, number);
    }
    else return [];
  }


}
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalService } from '../services/common/global.service';

@Pipe({ name: 'getProductImage' })
export class GetProductImagePipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer,
    private globalService: GlobalService
  ) { }
  transform(product: any) {
    if (product.thumbnails) {
      return product.thumbnails[0] ? product.thumbnails[0].imageUrl : '/assets/img/placeholder.jpg';
    }
    else if (product.thumbnail && this.globalService.isArray(product.thumbnail)) {
      return product.thumbnail[0] ? product.thumbnail[0] : '/assets/img/placeholder.jpg';
    }
    else {
      return product.thumbnail ? product.thumbnail : '/assets/img/placeholder.jpg';
    }
  }
}

import { Component, HostBinding } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'formly-group-faster-tucm',
  template: `
    <ng-container *ngFor="let f of field.fieldGroup">
      <formly-field [field]="f" *ngIf="!f.hide"></formly-field>
    </ng-container>
  `,
})
export class GroupFieldType extends FieldType {
  @HostBinding('class')
  public get klass() {
    return this.field && this.field.fieldGroupClassName || '';
  }

  public defaultOptions = {
    defaultValue: {},
  };
}
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UtilsModule } from '@core/utils/src/utils.module';
import { AboutComponent } from './about.component';

const routes: Routes = [
  {
    path: '', component: AboutComponent,
  }
]


@NgModule({
  declarations: [
    AboutComponent
  ],
  imports: [
    CommonModule,
    UtilsModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AboutModule { }

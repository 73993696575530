import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'core-image-type',
  templateUrl: './image-type.component.html',
  styleUrls: ['./image-type.component.scss']
})
export class ImageTypeComponent extends FieldType implements OnInit {
  imgWidth: number;
  imgHeight: number;

  onLoad(event) {
    console.log(event)
    this.imgWidth = event.target.naturalWidth;
    this.imgHeight = event.target.naturalHeight;
  }

  constructor() {
    super();
  }

  ngOnInit() {

  }

}


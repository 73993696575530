import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire//auth';
import { AngularFireDatabase } from '@angular/fire//database';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DestroyableComponent } from '@core/utils/src/components/destroyable/destroyable.component';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'core-order-base',
  template: ''
})
export class OrderBaseComponent extends DestroyableComponent implements OnInit {
  order: any;
  orders: any;

  constructor(
    public db: AngularFireDatabase,
    public route: ActivatedRoute,
    public router: Router,
    public afAuth: AngularFireAuth,
    private title: Title,
    public globalService: GlobalService,
    public localCacheService: LocalCacheService,
    private meta: Meta
  ) {
    super();
  }

  ngOnInit() {

    this.localCacheService.GroupInfo$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(list => {
        this.title.setTitle('Danh sách đơn hàng' + ' | ' + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_NGAN_GON) + '');

        this.meta.updateTag({ content: 'Danh sách đơn hàng' + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_DAY_DU) }, "name='description'");
      });


    this.orders = this.localCacheService.getOrders();

    this.route.params.subscribe((params: Params) => {
      if (params.key) {

        this.localCacheService.GroupInfo$
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe(list => {
            this.title.setTitle('Đơn hàng #' + params.key + ' | ' + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_NGAN_GON) + '');

            this.meta.updateTag({ content: 'Xem chi tiết đơn hàng' + params.key + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_DAY_DU) }, "name='description'");
          });


      }
      else {
        this.afAuth.authState
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe(currentUser => {
            const a = this.localCacheService.localDataFull['orders'].value;
            if (currentUser && currentUser.uid && a) {
              this.orders = a.filter(x => x.uid === currentUser.uid);
            }
          })
      }

    });
  }
}

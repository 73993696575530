import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalCacheService } from '../../services/common/local-cache.service';

@Component({
  selector: 'core-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  @Input() order: any;
  @Input() showFooter: Boolean;
  @Input() width: string;
  constructor(
    public router: Router,
    public localCacheService: LocalCacheService,
  ) {

  }
  getWidth() {
    if (this.width) {
      return this.width;
    } else {
      return '70%';
    }
  }
  ngOnInit() {

  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'getThumb' })
export class GetThumbPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(post: any) {
    if (post.thumbnail) {
      return post.thumbnail;
    } else {
      return '/assets/img/placeholder.jpg';
    }
  }
}

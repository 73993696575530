import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, args: string[]): string {
    // tslint:disable-next-line: radix
    const limit = args.length > 0 ? parseInt(args[0]) : 100;
    const trail = args.length > 1 ? args[1] : '...';

    value = (new DOMParser()).parseFromString(value, 'text/html').querySelectorAll('body')[0].innerText.trim();
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
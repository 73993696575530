import { Component } from '@angular/core';
import { BlogBaseComponent } from '@core/base-frontend/src/blog-base/blog-base.component';


@Component({
  selector: 'blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent extends BlogBaseComponent { }

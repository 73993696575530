import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
    selector: 'formly-wrapper-tabset',
    template: `
    <tabset>
        <ng-container #fieldComponent></ng-container>
    </tabset>
  `,
})
export class FormlyTabsetWrapper extends FieldWrapper {
    @ViewChild('fieldComponent', { read: ViewContainerRef ,static:false}) fieldComponent: ViewContainerRef;
}
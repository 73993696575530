import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-tab',
  template: `
    <tab [active]="active" [title]="title">
    <ng-container #fieldComponent></ng-container>
    </tab>
  `,
})
export class FormlyTabWrapper extends FieldWrapper {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static:false}) fieldComponent: ViewContainerRef;
  
  get active() {
      return this.to['active'];
  }
  
  get title() {
      return this.to['title'];
  }
}
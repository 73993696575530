import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire//database';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../services/common/global.service';
import { LocalCacheService } from '../services/common/local-cache.service';


@Injectable(
  {
    providedIn: 'root'
  }
)
export class UploadFileService {
  constructor(
    private db: AngularFireDatabase,
    public globalCache: LocalCacheService,
    public globalCache1: GlobalService,
    private toastr: ToastrService,
  ) { }

  private basePath = 'images';
  cnt: any;

  getFileUploads(numberItems): AngularFireList<any> {
    return this.db.list(`/${this.basePath}`, ref =>
      ref.limitToLast(numberItems));
  }

  confirmDelete(fileUpload) {
    this.globalCache1.showConfirmDelete()
      .subscribe(allowDelete => {
        if (allowDelete) {
          this.deleteFileUpload(fileUpload);
        }
      });
  }


  deleteFileUpload(fileUpload) {
    if (fileUpload.key) {
      this.db.object(`/${this.basePath}/${fileUpload.key}`).remove();
    }
    firebase.storage().refFromURL(fileUpload.url).delete();
    this.globalCache.increaseDB('images');
    this.toastr.success('Đã xóa ảnh ' + fileUpload.name + ' thành công trên server.');
  }

  uploadImages(croppedImages) {
    const result = [];
    const _this = this;
    this.cnt = croppedImages.length;
    croppedImages.forEach((timg: any) => {
      firebase.storage().ref().child(`/${this.basePath}/${timg.name}`)
        .putString(this.formatBase64(timg.src), 'base64', { contentType: timg.type })
        .then((snapshot) => {
          snapshot.ref.updateMetadata({ 'cacheControl': 'public, max-age=15552000' });
          snapshot.ref.getDownloadURL()
            .then((url) => {
              _this.cnt--;
              _this.toastr.info(`Đã up ảnh ${timg.name} lên server thành công`);
              const a = _this.db.list(`/${_this.basePath}`).push({ url: url, name: timg.name, date: (new Date()).toISOString() });
              result.push({ imageUrl: url });
              const b = croppedImages.find(x => x.name === timg.name);
              b.imageUrl = url;
              b.key = a.key;
              this.globalCache.increaseDB('images');
            });
        });
    });
  }

  formatBase64(strToReplace) {
    return strToReplace.replace(/^data:image\/[a-z]+;base64,/, "");
  }


}


import { Injectable, OnDestroy } from "@angular/core";
import { AngularFireDatabase } from '@angular/fire//database';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CommonFunctionsService } from './common-functions.service';

@Injectable({
  providedIn: 'root'
})
export class LocalCacheService extends CommonFunctionsService implements OnDestroy {

  constructor(
    public db: AngularFireDatabase,
    // public filterGroupInfo: FilterGroupInfoPipe
  ) {
    super();
    this.projectName = localStorage.getItem('projectName');
    this.dbTimeName = 'db-' + this.projectName;
    this.tableNames.forEach(name => {
      this.localDataFull[name] = new BehaviorSubject([]);
    });

    this.localStoreDB = JSON.parse(localStorage.getItem(this.dbTimeName));
    if (!this.localStoreDB) {
      this.reloadFirstTime = true;
      this.getDBTimestamp();
    }
    else {
      // //LOAD ALL FROM LOCAL STORAGE FIRST
      this.reloadLocalDB();
    }

    this.GroupInfo$ = !this.commonInfos ? this.db
      .list("/infos")
      .snapshotChanges()
      .pipe(map(actions => actions.map((a: any) => ({ ...a.payload.val() })))) : of(this.commonInfos);
  }
  private unsubscribeAll = new Subject<void>();

  public projectName: string;
  private dbTimeName: string;
  public localStoreDB: any;
  public listFeatureHomeDisplay = [];
  private reloadFirstTime = false;

  //All the data is read from localStorage
  public localDataFull = {};
  public localTimestamp = [];

  //All table name need to save and check
  public tableNames = [
    'products',
    'posts',
    'teambuildings',
    'events',
    'menu-front',
    'infos',
    'categories',
    'pages',
    'images',
    'features',
    'settings',
    'admins',
    'users',
    'menu-admin',
    'orders',
  ]

  public commonInfos: any;
  public commonSettings: any;
  public GroupInfo$: Observable<any>;

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  private reloadLocalDB() {
    this.tableNames.forEach(name => {

      this.localTimestamp = this.localStoreDB['db-timestamps'];
      if (this.localTimestamp) {
        const localValue = this.localTimestamp.find(a => a.name === name);
        if (!localValue) {
          this.downloadDB(name);
        }
        else {
          const data = this.localStoreDB[name];
          if (data && data.length > 0) {
            // pre-calculate Data on-the-go
            if (name === 'categories') {
              if (this.localStoreDB['features']) {
                const features = JSON.parse(JSON.stringify(this.localStoreDB['features']));
                const categories = JSON.parse(JSON.stringify(this.localStoreDB['categories']));
                const products = JSON.parse(JSON.stringify(this.localStoreDB['products']));
                let result = categories;
                if (features && features.length > 0) {
                  const lstCategoryFeature = [];
                  for (const [key, value] of Object.entries(features[0])) {
                    const a = value as any;
                    if (a.CategoryIds) {
                      Object.entries(a.CategoryIds).forEach(cat => {
                        const chk = cat[1];
                        if (chk === true) {
                          const k = cat[0];
                          const cf = lstCategoryFeature.find(x => x.key === k);
                          this.calcProductIds(products, a);
                          const lo = a.ListOptions ? [...a.ListOptions] : null;
                          const ft = {
                            FeatureName: a.FeatureName,
                            ListOptions: lo,
                            FeatureOrder: a.FeatureOrder
                          };
                          if (cf) {
                            cf.features.push(ft);
                          }
                          else {
                            lstCategoryFeature.push({
                              key: k,
                              features: [{ ...ft }]
                            });
                          }
                        }
                      });
                    }
                  }
                  result = categories.map((e) => {
                    for (const f of lstCategoryFeature) {
                      if (e.key === f.key)
                        Object.assign(e, f);
                    }
                    return e;
                  });
                }
                this.localDataFull['categories-temp'] = new BehaviorSubject([]);
                this.localDataFull['categories-temp'].next(result);
              }
              this.localDataFull[name].next(data);
            }
            else if (name === 'features') {
              const features = JSON.parse(JSON.stringify(this.localStoreDB['features']));
              let sortedByFeatureOrder = {};

              if (this.localStoreDB['products']) {
                const products = JSON.parse(JSON.stringify(this.localStoreDB['products']));
                if (features && features.length > 0) {
                  for (const [key, value] of Object.entries(features[0])) {
                    const a = value as any;

                    this.calcProductIds(products, a);
                  }
                  this.localDataFull['features-temp'] = new BehaviorSubject([]);
                  this.localDataFull['features-temp'].next(features);
                }

                sortedByFeatureOrder = Object.entries(features[0]).sort((a, b) => {
                  return a[1]['FeatureOrder'] - b[1]['FeatureOrder'];
                }).reduce(function (acc, cur, i) {
                  if (typeof cur[1] === 'object') {
                    acc[i] = cur[1];
                  } else {
                    //acc[i] = { 'key': cur[1] }
                  }
                  return acc;
                }, {});
                this.listFeatureHomeDisplay = Object.entries(sortedByFeatureOrder).filter((a, b) => {
                  return (a[1] as any).FeatureName;
                }).map((a, b) => (a[1] as any).FeatureName);
              }
              this.localDataFull[name].next([sortedByFeatureOrder]);
            }
            else if (name === 'infos') {
              this.commonInfos = JSON.parse(JSON.stringify(data));
              this.localDataFull[name].next(data);
            }
            else if (name === 'settings') {
              const d = JSON.parse(JSON.stringify(data));
              let result = d;
              const tempp = [Object.keys(d[0]).map(function (key) {
                if (d[0][key].url) {
                  return { [d[0][key].url]: d[0][key].values };
                }
              })];
              result = Object.assign({}, ...tempp[0]);
              this.commonSettings = result;
              this.localDataFull[name].next(data);
              this.localDataFull['settings-temp'] = new BehaviorSubject([]);
              this.localDataFull['settings-temp'].next(result);
            }
            else {
              this.localDataFull[name].next(data);
            }
            // this.localDataFull[name].next(data);
          }
          else {
            this.downloadDB(name);
          }
        }
      }
    });
  }

  private calcProductIds(products: any, a: any) {
    if (a.ListOptions)
      a.ListOptions = a.ListOptions.map(b => ({ ...b, productIds: [] })); products.forEach(p => {
        if (p.features) {
          for (const [keyProductFeatureName, valueProductFeature] of Object.entries(p.features)) {
            if (keyProductFeatureName === a.FeatureName) {
              const t = a.ListOptions ? a.ListOptions.find(lo => lo.OptionName === valueProductFeature) : null;
              if (t && t.productIds && !t.productIds.some(a => a.key === p.key)) {
                (t.productIds as any[]).push({ key: p.key });
              }
            }
          }
        }
      });
  }

  //CHECK DB-TIMESTAMPS TO SEE ANY UPDATE
  getDBTimestamp() {
    const mysub = this.db.list('/db-timestamps').snapshotChanges()
      .pipe(map(actions =>
        actions.map((a: any) => ({ key: a.key, ...a.payload.val() }))
      ))
      .pipe(takeUntil(this.unsubscribeAll))
      //Subcribe to online DB to see any change and update localStorage
      .subscribe((latestOnlineTimestamps: any) => {
        let localStoreDB = JSON.parse(localStorage.getItem(this.dbTimeName));
        let localTimestamps = null;
        if (localStoreDB) {
          localTimestamps = localStoreDB['db-timestamps'];
        }
        else {
          localStoreDB = {};
        }
        const DBTimestampNew = <any>[];
        const epoch = new Date();
        let dbNull = false;
        if (!latestOnlineTimestamps || latestOnlineTimestamps.length === 0) {
          dbNull = true;
        }
        this.tableNames.forEach(name => {
          if (dbNull) {
            DBTimestampNew.push({
              name: name,
              datetime: epoch.toISOString()
            });
          }
          else {
            const d1 = latestOnlineTimestamps.find(a => a.name === name);
            DBTimestampNew.push({
              name: name,
              key: d1 ? d1.key : '',
              datetime: d1 ? d1.datetime : epoch.toISOString()
            });
            const d2 = localTimestamps && localTimestamps.find(a => a.name === name);
            if (!d2 || (d1 && d2 && d1.datetime !== d2.datetime)) {
              console.log('DB has change and reload local: ', name);
              this.downloadDB(name);
            }
          }
        });

        setTimeout(() => { //Đoạn này để xử lý nếu chưa có localStorage thì sẽ bị vỡ layout
          if (this.reloadFirstTime === true) {
            window.location.reload();
            this.reloadFirstTime = false;
          }
        }, 1000);
        localStoreDB['db-timestamps'] = DBTimestampNew;
        localStorage.setItem(this.dbTimeName, JSON.stringify(localStoreDB));
        if (dbNull) {
          mysub.unsubscribe(); // unsubscribe if not will cause infinity push to db-timestamps
          DBTimestampNew.forEach(item => {
            this.db.list('/db-timestamps').push(item);
          });
          this.getDBTimestamp();
        }
      });
  }

  downloadDB(name: string) {
    const sub3 = this.db.list('/' + name).snapshotChanges()
      .pipe(map(actions =>
        actions.map((a: any) => ({ key: a.key, ...a.payload.val() }))
      )).subscribe((newestValues: any) => {
        // tslint:disable-next-line: prefer-const
        let localStoreDB = JSON.parse(localStorage.getItem(this.dbTimeName));
        if (!localStoreDB) {
          localStoreDB = {};
        }
        localStoreDB[name] = newestValues;
        localStorage.setItem(this.dbTimeName, JSON.stringify(localStoreDB));
        this.localDataFull[name].next(newestValues);
        sub3.unsubscribe();
      });
  }

  findTableInLocalStorage(name) {
    return this.localTimestamp.find(a => a.name === name);
  }

  increaseDB(name) {
    const timestampInStorage = this.findTableInLocalStorage(name);
    if (timestampInStorage && timestampInStorage.key && timestampInStorage.key !== '') {
      this.updatedbtimestamps(name, timestampInStorage.key);
    }

    // else { Trường hợp này là muốn restore từ DB Online nếu localStorage chưa có
    //   const sub4 = this.db.list('/db-timestamps').snapshotChanges()
    //     .pipe(map(actions =>
    //       actions.map(a => ({ key: a.key, ...a.payload.val() }))
    //     ))
    //     .subscribe((latestOnlineTimestamps: any) => {
    //       sub4.unsubscribe();
    //       const a = latestOnlineTimestamps.find(a => a.name === name);
    //       if (a) {
    //         this.updatedbtimestamps(name, a.key);
    //         this.updateKeyLocalTimestamps(name, a.key);
    //       }
    //     });
    // }
  }

  updatedbtimestamps(name: string, key: string) {
    this.db.object('/db-timestamps/' + key).set({
      name: name,
      datetime: (new Date()).toISOString()
    });
  }

  getLocalDB(name: string): Observable<any> {
    const temp = this.localDataFull[name];
    if (temp) {
      return temp.asObservable();//.pipe(share());
    }
    else {
      const tsub = this.db.list('/' + name).valueChanges().subscribe(val => {
        tsub.unsubscribe();
        this.localDataFull[name].next(val);
      });
      return this.db.list('/' + name).valueChanges();
      //return of([]);
    }
  }

  getLocalDBArray(name: string): any[] {
    const temp = this.localDataFull[name].value;
    if (temp) {
      return temp;
    }
    else {
      return [];
    }
  }


  updateLocalDB(name: string, key: string, data: any) {
    const localStoreDB = JSON.parse(localStorage.getItem(this.dbTimeName));
    if (localStoreDB) {
      const localValue = localStoreDB[name];
      if (localValue && localValue.length > 0) {
        const foundIndex = localValue.findIndex(a => a.key === key);
        if (foundIndex) {
          localValue[foundIndex] = data;
          localStorage.setItem(this.dbTimeName, JSON.stringify(localStoreDB));
        }
      }
    }
  }

  updateKeyLocalTimestamps(name: string, key: string) {
    const localStoreDB = JSON.parse(localStorage.getItem(this.dbTimeName));
    if (localStoreDB) {
      const timestamps = localStoreDB['db-timestamps'];
      if (timestamps && timestamps.length > 0) {
        const foundIndex = timestamps.findIndex(a => a.name === name);
        if (foundIndex) {
          timestamps[foundIndex].key = key;
          localStorage.setItem(this.dbTimeName, JSON.stringify(localStoreDB));
        }
      }
    }
  }


  restoreLocalDBtoOnlineDB(localName: string, onlineName: string) {
    const localStoreDB = JSON.parse(localStorage.getItem(this.dbTimeName));
    if (localStoreDB) {
      const localValue = localStoreDB[localName];
      if (localValue) {
        const sub = this.db.list(onlineName).valueChanges().subscribe(val => {
          sub.unsubscribe();
          if (val && val.length > 0) {
            this.insertAlltoFirebaseDB(localValue, onlineName);
          }
          else {
            this.insertAlltoFirebaseDB(localValue, onlineName);
          }
        });
      }
    }
  }

  insertAlltoFirebaseDB(arr: any, onlineName: string) {
    // this.db.list(onlineName).remove();
    if (this.isObjectNotArray(arr)) {
      const k = arr.key + '';
      delete arr.key;
      this.db.object(onlineName + '/' + k).set(arr);
    } else {
      arr.forEach(e => {
        const k = e.key + '';
        delete e.key;
        this.db.object(onlineName + '/' + k).set(e);
      });
    }
  }

  genFeatureTable(product) {
    if (product.features) {
      return this.listFeatureHomeDisplay
        .filter(k => {
          return product.features[k] && product.features[k] !== ''
        }).map(k => ({ value: k, label: product.features[k] }));
    }
    else {
      return [];
    }
  }

  getPostsPublishedAsObservable(number?: number): Observable<any> {
    return this.getLocalDB('posts')
      .pipe(map(a => this.filterTinTuc(a)), takeUntil(this.unsubscribeAll))
      .pipe(map(a => number ? a.slice(0, number) : a));
  }

  getPostsPublishedAsArray(number?: number): any[] {
    const pp = this.localDataFull['posts'].value;
    if (pp) {
      const a = this.filterTinTuc(pp);
      return number ? a.slice(0, number) : a
    } else {
      return []
    }
  }


  getProductsAsArray(number?: number): any[] {
    const pp = this.localDataFull['products'].value;
    if (pp) {
      const a = this.filterSanPham(pp);
      return number ? a.slice(0, number) : a
    } else {
      return []
    }
  }

  filterTinTuc(pp) {
    return pp.filter(
      b => b.published !== false && (b.type === "Tin tức" || !b.type)
    ).sort((a: any, b: any) => {
      return Number(new Date(b.date)) - Number(new Date(a.date));
    });
  }


  filterSanPham(pp) {
    return pp.filter(
      b => b.published !== false
    ).sort((a: any, b: any) => {
      return Number(new Date(b.date)) - Number(new Date(a.date));
    });
  }


  getProductsPublishedAsObservable(number?: number): Observable<any> {
    return this.getLocalDB('products')
      .pipe(map(a => a.filter(

        b => b.published !== false

      )), takeUntil(this.unsubscribeAll))
      .pipe(map(a => number ? a.slice(0, number) : a));
  }

  getProductsPublishedAsArray(number?: number): any[] {
    const pp = this.localDataFull['products'].value;
    if (pp) {
      const a = pp.filter(
        b => b.published !== false
      );
      return number ? a.slice(0, number) : a
    } else {
      return []
    }
  }


  getProductsPublishedAsArrayRandom(number?: number): any[] {

    const pp = this.localDataFull['products'].value;
    const q = [];
    if (pp) {
      const a = pp.filter(
        b => b.published !== false
      );
      const n = number ? number : 0;
      for (let i = 0; i < n; i++) {
        q.push(a[this.getRandomInt(0, pp.length)]);
      }
      return q;
    } else {
      return []
    }
  }

  getProductsRelated(productId: string, number?: number): any[] {
    const pp = this.localDataFull['products'].value;
    if (pp) {
      const a = pp.filter(
        b => b.published !== false &&
          b.key !== productId
      );
      return number ? a.slice(0, number) : a
    } else {
      return []
    }
  }

  getPostsByType(type: string, number?: number) {
    const pp = this.localDataFull['posts'].value;
    if (pp) {
      const a = pp.filter(
        b => b.published !== false && b.type &&
          b.type.toLowerCase() === type.toLowerCase()
      ).sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return (dateB as any) - (dateA as any);
      });
      return number ? a.slice(0, number) : a
    } else {
      return []
    }
  }


  getOrderByKey(key: any): any {
    const co = this.localDataFull['orders'].value;
    if (co) {
      const a = co.find(b => b.key === key);
      if (a && this.isObjectNotArray(a.items)) {
        a.products = [];
        Object.entries(a.items).forEach(e => {
          a.products.push(e[1]);
        });
      }
      return a ? a : {}
    } else {
      return {};
    }
  }

  getOrders(): any[] {
    const a = this.localDataFull['orders'].value;
    return a ? a : []
  }

  getRootCategoriesWithAllProducts() {
    const ps = JSON.parse(JSON.stringify(this.localDataFull["products"].value.sort((a, b) => {
      return a.weight < b.weight ? 1 : a.weight > b.weight ? -1 : 0
    })));
    const cs = JSON.parse(JSON.stringify(this.localDataFull["categories"].value));
    let result = [];
    if (ps && ps.length > 0 && cs && cs.length > 0) {
      ps.forEach(p => {
        for (let i = 0; i < cs.length; i++) {
          const c = cs[i];
          if (c.key === p.category) {
            const root = this.findRootParent(cs, c);
            if (!root.products || (root.products && this.isObjectNotArray(root.products))) root.products = [];
            root.products.push(p);
            break;
          }
        }
      });
      //Chỉ lấy root categories và chỉ lấy category có products trong đó để hiện lên trang chủ
      result = cs.filter(a => !a.parent_id && a.products);
      result = result.map(a => ({
        ...a,
        products: a.products.sort((a, b) => {
          return a.weight < b.weight ? 1 : a.weight > b.weight ? -1 : 0
        })
      }));
      return result;
    }
    else {
      return [];
    }
  }

  findRootParent(items, item) {
    if (!item) {
      return {};
    }
    if (item.parent_id) {
      return this.findRootParent(items, items.find(a => a.key === item.parent_id));
    } else {
      return item
    }
  }


  public getCommonInfoDisplay(name: any) {

    if (!this.commonInfos) {
      return '';
    }
    else {
      const a = this.commonInfos[name];
      if (a && a.length > 0) {
        return a[0].display ? a[0].display : a[0].imageUrl;
      } else {
        return '';
      }
    }


  }

  public getCommonInfoDisplayArray(name: any) {
    if (!this.commonInfos) {
      return this.db.list('/infos').snapshotChanges()
        .pipe(map(actions =>
          actions.map((a: any) => ({ key: a.key, ...a.payload.val() })).map(b => {
            return Object.values(b).find((c: any) => c.url === name)
          })
        ));
    }
    else {
      return of(this.commonInfos[name]);
    }
  }


  public FilterGroupInfo(arr, group_code, child_url) {
    let a = "";
    let b = [];
    let type = 'string';

    if (group_code === '') {
      group_code = this.DUNG_CHUNG;
    }

    // console.log(group_code);
    // console.log(child_url);
    // console.log( arr[1]);
    // console.log( Object.values(arr[1]));

    if (arr && arr.length > 0) {
      const result = Object.values(arr[0]).filter(
        (c: any, b: any) => c.group_code === group_code
      ) as any;
      if (result && result.length > 0) {
        const ss = result[0].content;
        const tt = ss.filter((c: any, b: any) => c.url === child_url);
        if (tt && tt.length > 0 && tt[0].values && tt[0].values.length > 0) {
          const yy = tt[0].values;
          b = yy;
          // console.log(b);
          if (b && b.length > 0) {
            a = Object.values(b[0])[0] + '';
          }
          if (b.length > 1) {
            type = 'array';
          }
        }
      }
    }
    return type === "string" ? a : b;
  }


}
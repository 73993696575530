import { Pipe, PipeTransform } from "@angular/core";
import { LocalCacheService } from '../services/common/local-cache.service';

@Pipe({
  name: "filterGroupInfo"
})
export class FilterGroupInfoPipe implements PipeTransform {

  constructor(
    public localCacheService: LocalCacheService) {

  }

  transform(
    list: any,
    group_code: string,
    child_url: string,
  ): any {
    return this.localCacheService.FilterGroupInfo(list, group_code, child_url);
  }
}

/* ===== Data of Infos DB ===== */

// [
//    {
//     "0": {
//       "content": [
//         {
//           "title": "Số điện thoại 1",
//           "url": "so-dien-thoai-1",
//           "values": [
//             {
//               "display": "23525325325",
//               "type": "display1"
//             }
//           ]
//         },
//         {
//           "title": "Thông tin 1",
//           "url": "thong-tin-1",
//           "values": [
//             {
//               "display": "sbdsbsđsdsd",
//               "type": "display1"
//             }
//           ]
//         }
//       ],
//       "group_code": "dung-chung",
//       "group_name": "Dùng chung"
//     },
//     "1": {
//       "content": [
//         {
//           "title": "Tên công ty (ngắn gọn)",
//           "url": "ten-cong-ty-ngan-gon",
//           "values": [
//             {
//               "display": "tên tên",
//               "type": "display1"
//             }
//           ]
//         },
//         {
//           "title": "Tiêu đề 1",
//           "url": "tieu-de-1",
//           "values": [
//             {
//               "display": "Chính xáhc cnàinf",
//               "type": "display1"
//             }
//           ]
//         }
//       ],
//       "group_code": "trang-chu",
//       "group_name": "Trang chủ"
//     },
//     "key": "-LxBHcZlRFeNNREIz23D"
//   }
// ]

/* ===== Data of Infos DB ===== */

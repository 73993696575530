import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public about: any;

  constructor(public db: AngularFireDatabase,
    public localCacheService: LocalCacheService
  ) {

    db.list('/abouts').snapshotChanges()
      .pipe(map(actions =>
        actions.map(a => ({ ...<any>a.payload.val() }))
      )).subscribe(items => {
        this.about = items[0];
      });
  }

  ngOnInit() {
  }

}

import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire//auth';
import { AngularFireDatabase } from '@angular/fire//database';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DestroyableComponent } from '@core/utils/src/components/destroyable/destroyable.component';
import { CartService } from '@core/utils/src/services/common/cart.service';
import { DynamicScriptAndStyleLoaderService } from '@core/utils/src/services/common/dynamic-loader.service';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import { ProductFilterComponent } from '@core/utils/src/shared/product-filter/product-filter.component';
import { TreeFilterComponent } from '@core/utils/src/shared/tree-filter/tree-filter.component';
import { DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'core-products-base',
  template: '',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsBaseComponent extends DestroyableComponent {

  @ViewChild('btnExpand', { static: true }) btnExpand: ElementRef<HTMLElement>;
  @ViewChild('sliderRef', { static: false }) sliderRef: ElementRef;
  @ViewChild('treeFilterCore', { static: false }) treeFilterCore: TreeFilterComponent;
  @ViewChild('productFilterCore', { static: false }) productFilterCore: ProductFilterComponent;

  public ch = new BehaviorSubject({});

  someKeyboardConfig: any = {
    connect: true,
    start: [0, 1000000000],
    step: 100000,
    tooltips: [
      {
        from(value: number): string {
          return (new CurrencyPipe('vi')).transform(value, 'VND', 'symbol');
        },
        to(value: number): string {
          return (new CurrencyPipe('vi')).transform(value, 'VND', 'symbol');
        }
      }, {
        from(value: number): string {
          return (new CurrencyPipe('vi')).transform(value, 'VND', 'symbol');
        },
        to(value: number): string {
          return (new CurrencyPipe('vi')).transform(value, 'VND', 'symbol');
        }
      }],
    range: {
      min: 0,
      max: 1000000000
    },
    behaviour: 'drag',
  };
  products: any;
  categories: Observable<any[]>;
  p: any;
  CategoryIds = {};
  ProductFeatures: any;
  tempProducts = [];
  filterRules = {};
  treeView: DxTreeViewComponent;
  expanded = false;
  priceRange: any;
  sortPriceAsc: any;
  displayPageCount = 10;
  myCat = [];
  myCat1: any[];
  allProducts = [];

  constructor(
    public cd: ChangeDetectorRef,
    public db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    public globalService: GlobalService,
    public localCart: CartService,
    public router: Router,
    public route: ActivatedRoute,
    public activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public title: Title,
    public meta: Meta,
    public dynamicScriptLoader: DynamicScriptAndStyleLoaderService,
    public localCacheService: LocalCacheService
  ) {
    super();
    this.categories = this.localCacheService.getLocalDB('categories').pipe(map((data) => {
      data.sort((a, b) => {
        return a.weight < b.weight ? -1 : 1;
      });
      return data;
    }));

    this.myCat = this.localCacheService.getLocalDBArray('categories').sort((a, b) => {
      return a.weight < b.weight ? -1 : 1;
    })

    // this.myCat = this.localCacheService.makeTreeWithChildrenPrimeNg(this.localCacheService.getLocalDBArray('categories'), 'weight');

    this.localCacheService.getProductsPublishedAsObservable()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(items => {
        if (items && items.length > 0) {
          this.products = items.sort((a, b) => {
            return a.weight > b.weight ? 1 : a.weight < b.weight ? -1 : 0
          });
          this.settingPriceFilter();
          this.tempProducts = JSON.parse(JSON.stringify(items));
          const lst = [];
          items.forEach(a => {
            if (a.features) {
              for (const [key, value] of Object.entries(a.features)) {
                lst.push({
                  name: key,
                  value: value,
                  productkey: a.key
                });
              }
            }
          });
          this.ProductFeatures = this.globalService.groupByProperties(lst, ['name', 'value', 'productkey']);
        }
      });

    this.route.paramMap
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((params: Params) => {

        this.localCacheService.GroupInfo$
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe(list => {
            this.title.setTitle('Danh mục sản phẩm' + ' | ' + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_NGAN_GON) + '');

            this.meta.updateTag({ content: 'Danh mục sản phẩm ' + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_DAY_DU) }, "name='description'");
          });
        const slug = params.get('slug');
        // this.categories.subscribe(cats => {
        //   this.myCat = cats;
        const id = this.myCat.find(x => x.slug === slug);
        if (id) {
          this.CategoryIds = { [id.key]: true };
          this.myCat.find(a => a.key === id.key).expanded = true;
          this.expanded = false;
          this.localCacheService.GroupInfo$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(list => {
              this.title.setTitle(id.name + ' | ' + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_NGAN_GON) + '');

              this.meta.updateTag({ content: id.name + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_DAY_DU) }, "name='description'");
            });

          this.meta.updateTag({ content: id.meta_description }, "name='description'");
          this.meta.updateTag({ content: id.meta_keyword }, "name='meta_keyword'");
          this.meta.updateTag({ content: id.meta_description }, "name='meta_description'");
          this.meta.updateTag({ content: id.meta_title }, "name='meta_title'");
          const a = setInterval(() => {
            if (this.treeView) {
              clearInterval(a);
              this.treeFilterCore.resetChecked.next(this.CategoryIds);
              this.emitChangeValue();
              if (id && id.parent_id !== 0) {
                this.filterRules = {};
                this.resetfilterCheckbox();
                this.filterRules['category'] = [id.key];
                this.products = this.globalService.filterPlainArray(this.tempProducts, this.filterRules);
                this.settingPriceFilter();
              }
            }
          }, 500);

        }
        else {
          if (slug.indexOf("tour-cuoi-tuan") !== -1 || slug.indexOf("teambuilding-trong-ngay") !== -1) {  //hittravel
            this.filterRules['category'] = [];
            this.CategoryIds = [];
            if (this.treeView && this.treeView.instance) { this.treeView.instance.unselectAll(); }
            let ft = '';
            if (slug === 'teambuilding-trong-ngay') {
              ft = 'TEAM BUILDING TRONG NGÀY';
            }
            else if (slug === 'tour-cuoi-tuan') {
              ft = 'Du lịch cuối tuần';
            }
            this.filterRules = {
              'Loại tour': [ft]
            }
            this.checkFilterCheckbox(ft);
            this.products = this.globalService.filterPlainArray(this.tempProducts, this.filterRules);
          }
        }
        // });
      });
  }

  emitChangeValue() {
    setTimeout(() => {
      this.filterRules = {};
      this.resetfilterCheckbox();
      const bb = this.treeView ? (this.treeView.instance as any).getSelectedNodesKeys() : [];
      this.filterRules['category'] = bb;
      this.products = this.globalService.filterPlainArray(this.tempProducts, this.filterRules);
      this.ch.next('ckjsdc');
      this.settingPriceFilter();
    }, 500);
  }


  onPageChange(page: number) {
    this.p = page;
    window.scrollTo(0, 0);
  }



  getMinMaxPriceRange(data) {
    if (data && data.length > 0) {
      return {
        max: data.reduce((max, p) => Math.min(max, p.priceOut) ? p.priceOut : max, data[0].priceOut),
        min: data.reduce((min, p) => Math.min(min, p.priceOut) ? p.priceOut : min, data[0].priceOut),
      }
    }
    else {
      return {
        max: 0,
        min: 0,
      }
    }
  }

  minMax(items) {
    return items.reduce((acc, val) => {
      acc[0] = (acc[0] === undefined || val.priceOut < acc[0]) ? val.priceOut : acc[0]
      acc[1] = (acc[1] === undefined || val.priceOut > acc[1]) ? val.priceOut : acc[1]
      return acc;
    }, []);
  }

  sortByPriceOut() {
    const prop = 'priceOut';
    if (this.sortPriceAsc === true) {
      return this.products.sort((a, b) => parseInt(a[prop]) > parseInt(b[prop]) ? 1 : parseInt(a[prop]) === parseInt(b[prop]) ? 0 : -1);
    } else if (this.sortPriceAsc === false) {
      return this.products.sort((a, b) => parseInt(a[prop]) < parseInt(b[prop]) ? 1 : parseInt(a[prop]) === parseInt(b[prop]) ? 0 : -1);
    }
    else {
      if (this.localCacheService.projectName === 'hittravel') {
        return this.products.sort(x => x.showHome ? -1 : 1);
      }
      else {
        return this.products.sort((a: any, b: any) => {
          return Number(new Date(b.dateUpdated)) - Number(new Date(a.dateUpdated));
        })
      }
    }
  }

  rangeChanged(value) {
    this.filterRules['priceOut'] = value;
    this.products = this.globalService.filterPlainArray(this.tempProducts, this.filterRules);
  }

  settingPriceFilter() {
    // this.priceRange = this.getMinMaxPriceRange(this.products);
    this.priceRange = this.minMax(this.products);
    let min = parseInt(this.priceRange[0]);
    let max = parseInt(this.priceRange[1]);
    if (min === max) {
      min = 0;
      if (max === 0)
        max = 1;
    }
    this.someKeyboardConfig.start = [min, max];
    this.someKeyboardConfig.range = { min: min, max: max };
    if (this.sliderRef && (this.sliderRef as any).slider)
      (this.sliderRef as any).slider.set([min, max]);
  }

  getTreeView(event) {
    this.treeView = event;
  }

  checkedFilterChange(name, option) {
    this.filterRules['category'] = [];
    this.CategoryIds = [];
    if (this.treeView && this.treeView.instance) { this.treeView.instance.unselectAll(); }
    if (option.checked === true) {
      if (!this.filterRules[name]) {
        this.filterRules[name] = [];
      }
      if (this.filterRules[name].indexOf(option.name) === -1) {
        this.filterRules[name].push(option.name);
      }
    }
    else {
      if (this.filterRules[name]) {
        this.filterRules[name] = this.filterRules[name].filter(x => option.name !== x);
      }
    }
    this.products = this.globalService.filterPlainArray(this.tempProducts, this.filterRules);
    this.settingPriceFilter();
  }
  expandAllFilterTree() {
    if (this.expanded === false) {
      this.btnExpand.nativeElement.innerText = "(Thu gọn)";
      if (this.treeView) this.treeView.instance.expandAll();
      this.expanded = true;
    } else {
      this.btnExpand.nativeElement.innerText = "(Mở rộng)";
      if (this.treeView) this.treeView.instance.collapseAll();
      this.expanded = false;
    }
  }

  checkFilterCheckbox(id) {
    this.ProductFeatures = this.ProductFeatures.map(a => (a.value ? {
      name: a.name, value: a.value.map(b => {
        if (b.name === id) {
          return { ...b, checked: true }
        } else {
          return b
        }
      })
    } : { ...a }));
  }

  resetfilterCheckbox() {
    this.ProductFeatures = this.ProductFeatures.map(a => (a.value ? { name: a.name, value: a.value.map(b => ({ ...b, checked: false })) } : { ...a }));
  }

}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FirebaseApp } from '@angular/fire';
import { AngularFireDatabase } from '@angular/fire//database';
import 'firebase/auth';
import 'firebase/storage';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { LocalCacheService } from '../../services/common/local-cache.service';
import { GridImagesComponent } from '../grid-images/grid-images.component';
import { UploadFileService } from '../upload-file.service';

declare var $: any;
@Component({
  selector: 'core-crop-image',
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CropImageComponent implements OnInit {

  @ViewChild("tableContent", { read: TemplateRef, static: true })
  tableContentRef: TemplateRef<any>;
  @Input() showSelectFromServer = '0';
  @Output() outListFirebaseUploaded = new EventEmitter();

  cropWidth = '';
  cropQuality = 60;
  cropHeight = '';
  cropType = 'jpeg';
  file: any;
  storageRef: any;
  newThumbnail: any[];
  url: string;
  croppedImages = [];
  cFiles = [];
  keepSpectRatio = false;
  backgroundColor: any;
  window: any;
  constructor(
    public cdf: ChangeDetectorRef,
    public toastr: ToastrService,
    public af: FirebaseApp,
    public db: AngularFireDatabase,
    public globalCache: LocalCacheService,
    public uploadFileService: UploadFileService,
    public modalService: BsModalService,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
    this.window = (<any>window);
    const a = setInterval(() => {
      if (typeof $ !== 'undefined') {
        clearInterval(a);
        $(document).on('hidden.bs.modal', '.modal', function () {
          if ($('.modal:visible').length > 0) {
            $(document.body).addClass('modal-open');
          }
        });
      }
    }, 100);
  }
  getspectRatio() {
    if (this.cropWidth && this.cropHeight) {
      this.keepSpectRatio = true;
      // tslint:disable-next-line: radix
      return parseInt(this.cropWidth) / parseInt(this.cropHeight)
    } else {
      return 1;
    }
  }

  getFormat(ext) {
    if (this.cropType === '*') {
      return ext + '';
    } else {
      return this.cropType
    }
  }

  getFilenameExtension(file) {
    let ex = file.name.split(".").pop();
    if (ex === "jpg") ex = "jpeg";
    console.log(ex);
    return ex;
  }

  getBackgroundColor(item) {
    let color = '#fff';
    if (this.cropType === 'png') { color = null; }
    else {
      if (this.cropType === '*' && item.type.indexOf('png') !== -1) {
        color = null;
      }
    }
    return color
  }


  fileChangeEvent(event: any): void {
    this.cropWidth = "";
    this.cropHeight = "";
    this.keepSpectRatio = false;
    this.cFiles = [];
    this.croppedImages = [];
    Array.from(event.target.files).forEach((file: any) => {
      this.cFiles.push({
        ext: this.getFilenameExtension(file),
        file: file,
        type: file.type
      });
      this.croppedImages.push({
        osize: (file.size / 1024).toFixed(0) + 'KB',
        name: file.name.replace(/(\.[\w\d_-]+)$/i, '_' + new Date().getTime() + '$1'),
        type: this.cropType ? 'image/' + this.cropType : file.type,
      });
    });
    if (this.cFiles.length > 0) {
      this.bsModalRef = this.modalService.show(this.tableContentRef, {
        id: 100, class: 'modal-xl', keyboard: false, backdrop: true, ignoreBackdropClick: true, animated: false
      });
    }
  }

  imageCropped(index, event: ImageCroppedEvent) {

    if (event.width > 1920) {
      this.cropWidth = "1920";
      this.cropHeight = Math.round(event.height / event.width * 1920) + "";
    }
    const timg = {
      src: event.base64,
      width: event.width,
      height: event.height,
      owidth: !this.croppedImages[index].owidth ? event.width : this.croppedImages[index].owidth,
      oheight: !this.croppedImages[index].oheight ? event.height : this.croppedImages[index].oheight,
      size: this.getFileSize(event.base64),
      osize: this.croppedImages[index].osize,
      name: this.croppedImages[index].name,
      type: this.croppedImages[index].type,
      key: ''
    }
    this.croppedImages[index] = timg;
  }

  getFileSize(src) {
    if (src) {
      const base64Length = src.length - (src.indexOf(",") + 1);
      const padding =
        src.charAt(src.length - 2) === "="
          ? 2
          : src.charAt(src.length - 1) === "="
            ? 1
            : 0;
      const fileSize = ((base64Length * 0.75 - padding) / 1024).toFixed(0);
      return fileSize + 'KB';
    }
    return "0KB";
  }

  UploadAllCroppedImages() {
    this.bsModalRef.hide();
    this.uploadFileService.uploadImages(this.croppedImages);
    const a = setInterval(() => {
      if (this.uploadFileService.cnt === 0) {
        clearInterval(a);
        // this.outListFirebaseUploaded.emit(this.croppedImages.map(x => ({
        //   url: x.url,
        //   name: x.name,
        //   key: x.key
        // })));
        this.outListFirebaseUploaded.emit(this.croppedImages.map(x => x.imageUrl));
      }
    }, 100);
  }

  cancelCrop() {
    if (confirm('Bạn chưa lưu các ảnh đã cắt, bạn có muốn huỷ bỏ toàn bộ không?')) {
      this.cropWidth = '';
      this.cropHeight = '';
      this.keepSpectRatio = false;
      this.cropType = 'jpeg';
      this.bsModalRef.hide();
    }
  }

  openSelectImageFromServer() {
    this.bsModalRef = this.modalService.show(GridImagesComponent, { id: 99, class: 'modal-lg', keyboard: false, backdrop: true, ignoreBackdropClick: false, animated: false });
    this.bsModalRef.content.onClose.subscribe(result => {
      this.outListFirebaseUploaded.emit(result);
    });
  }


}

import { CommonModule, registerLocaleData } from '@angular/common';
import localeVi from '@angular/common/locales/vi';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CartHeaderBaseComponent } from '@core/base-frontend/src/cart-base/cart-header-base.component';
import { CartHeaderIconBaseComponent } from '@core/base-frontend/src/cart-base/cart-header-icon.component';
import { FrontBaseComponent } from '@core/base-frontend/src/home-base/front-base.component';
import { HomeBaseComponent } from '@core/base-frontend/src/home-base/home-base.component';
import { StopPropagationDirective } from '@core/utils/src/directives/stop-propagation.directive';
import { FormlyTypeModule } from '@core/utils/src/formly-type/formly-type.module';
import { CartService } from '@core/utils/src/services/common/cart.service';
import { UtilsModule } from '@core/utils/src/utils.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { DxTreeViewModule } from 'devextreme-angular';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'hammerjs';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlogModule } from './front/blog/blog.module';
import { AboutModule } from './front/common/about/about.module';
import { ContactModule } from './front/common/contact/contact.module';
import { PageModule } from './front/common/page/page.module';
import { SectionModule } from './front/common/section/section.module';
import { FrontComponent } from './front/front.component';
import { CartHeaderIconComponent } from './front/home/cart-header-icon/cart-header-icon.component';
import { CartHeaderComponent } from './front/home/cart-header/cart-header.component';
import { HomeComponent } from './front/home/home.component';
import { OrderModule } from './front/orders/order.module';
import { ProductModule } from './front/products/product.module';

registerLocaleData(localeVi, 'vi');

const firebaseConfig = {
  apiKey: "AIzaSyBmieuGP7K49CNGAW0UxkANS6f7fJqS-10",
  authDomain: "lovepet.firebaseapp.com",
  databaseURL: "https://lovepet.firebaseio.com",
  projectId: "lovepet",
  storageBucket: "lovepet.appspot.com",
  messagingSenderId: "345330769959",
  appId: "1:345330769959:web:93d805778722ee7df5ee02",
  measurementId: "G-PLY8DJMG1E"
}
firebase.initializeApp(firebaseConfig);


@NgModule({
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(firebaseConfig, 'firebase-cms'),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    NgxPaginationModule,
    FormlyTypeModule,
    UtilsModule,
    DxTreeViewModule,
    NgSelectModule,
    BlogModule,
    OrderModule,
    AboutModule,
    ContactModule,
    PageModule,
    ProductModule,
    SectionModule,
  ],
  declarations: [
    AppComponent,
    StopPropagationDirective,
    FrontComponent,
    FrontBaseComponent,
    CartHeaderBaseComponent,
    CartHeaderComponent,
    CartHeaderIconComponent,
    CartHeaderIconBaseComponent,
    HomeBaseComponent,
    HomeComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'vi-VN' },
    CartService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

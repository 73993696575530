import { Component } from '@angular/core';
import { CartHeaderBaseComponent } from '@core/base-frontend/src/cart-base/cart-header-base.component';

@Component({
  selector: 'cart-header',
  templateUrl: './cart-header.component.html',
  styleUrls: ['./cart-header.component.scss']
})
export class CartHeaderComponent extends CartHeaderBaseComponent {

}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { ProductBaseComponent } from '@core/base-frontend/src/product-base/product-base.component';
import { ProductsBaseComponent } from '@core/base-frontend/src/product-base/products-base.component';
import { TreeFilterModule } from '@core/utils/src/shared/tree-filter/tree-filter.module';
import { UtilsModule } from '@core/utils/src/utils.module';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { NouisliderModule } from 'ng2-nouislider';
import { NgxPaginationModule } from 'ngx-pagination';
import { SectionModule } from '../common/section/section.module';
import { ProductComponent } from './product/product.component';
import { ProductsComponent } from './products.component';

const routes: Routes = [
  { path: '', component: ProductsComponent },
  { path: 'category/:slug', component: ProductsComponent },
  { path: ':url', component: ProductComponent }
]


@NgModule({
  declarations: [
    ProductBaseComponent,
    ProductComponent,
    ProductsBaseComponent,
    ProductsComponent
  ],
  imports: [
    NouisliderModule,
    TreeFilterModule,

    CommonModule,
    UtilsModule,
    FormsModule,
    NgxPaginationModule,
    RouterModule.forChild(routes),
    GalleryModule.withConfig(
      {
        loadingMode: 'indeterminate',
        gestures: true,
        counter: false,
        loop: false
      }),
    LightboxModule,
    SectionModule,
  ],
  exports: [RouterModule]
})
export class ProductModule { }

import { Component } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire//database';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HomeBaseComponent } from '@core/base-frontend/src/home-base/home-base.component';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})


export class HomeComponent extends HomeBaseComponent {


  servicesList = [];
  data = [];



  constructor(
    public db: AngularFireDatabase,
    public toastr: ToastrService,
    public globalService: GlobalService,
    public localCacheService: LocalCacheService,
    public router: Router,
    public title: Title,
    public meta: Meta,
  ) {
    super(db, toastr, globalService, localCacheService, router, title, meta);


    this.localCacheService.GroupInfo$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(list => {
        this.data = this.localCacheService.FilterGroupInfo(list, 'trang-chu', 'gioi-thieu-dich-vu') as any[];

        for (let i = 2; i < this.data.length; i++) {
          this.servicesList.push(this.data[i]);
        }
      });

  }
}
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import * as dayjs from 'dayjs';
import 'dayjs/locale/vi';
import { loadMessages, locale } from 'devextreme/localization';


@Component({
  selector: 'core-app-base',
  template: ''
})
export class AppBaseComponent {

  constructor(
    public globalService: GlobalService,
    public localCacheService: LocalCacheService,
    private router: Router,
  ) {
    this.globalService.projectName = localStorage.getItem('projectName');
    this.localCacheService.getDBTimestamp();
    dayjs.locale('vi');
    loadMessages({
      'en': {
        Yes: 'Yes',
        No: 'No',
        Cancel: 'Hủy',
        Clear: 'Xóa',
        Done: 'Xong',
        Loading: 'Đang tải...',
        Select: 'Chọn...',
        Search: 'Tìm kiếm',
        Back: 'Quay lại',
        OK: 'OK',
        'dxCollectionWidget-noDataText': 'Chưa có dữ liệu.',
        'validation-required': 'Bắt buộc phải nhập',
        'validation-required-formatted': '{0} là bắt buộc phải nhập',
        'validation-numeric': 'Giá trị nhập vào phải là con số',
        'validation-numeric-formatted': '{0} phải là giá trị số',
        'validation-range': 'Giá trị vừa nhập nằm ngoài khoảng cho phép',
        'validation-range-formatted': '{0} nằm ngoài khoảng cho phép',
        'validation-stringLength': 'Độ dài của giá trị nhập vào không chính xác',
        'validation-stringLength-formatted': 'Độ dài {0} không chính xác',
        'validation-custom': 'Giá trị không hợp lệ',
        'validation-custom-formatted': '{0} không hợp lệ',
        'validation-compare': 'Giá trị không khớp',
        'validation-compare-formatted': '{0} không khớp',
        'validation-pattern': 'Giá trị không khớp với mẫu',
        'validation-pattern-formatted': '{0} không khớp với mẫu',
        'validation-email': 'Email không hợp lệ',
        'validation-email-formatted': '{0} không hợp lệ',
        'validation-mask': 'Giá trị không hợp lệ',
        'dxLookup-searchPlaceholder': 'Số chữ số tối thiểu: {0}',
        'dxList-pullingDownText': 'Kéo xuống để làm mới danh sách...',
        'dxList-pulledDownText': 'Thả chuột ra để làm mới danh sách...',
        'dxList-refreshingText': 'Đang làm mới danh sách...',
        'dxList-pageLoadingText': 'Đang tải...',
        'dxList-nextButtonText': 'Xem thêm',
        'dxList-selectAll': 'Chọn tất cả',
        'dxListEditDecorator-delete': 'Xóa',
        'dxListEditDecorator-more': 'Xem thêm',
        'dxScrollView-pullingDownText': 'Kéo xuống để làm mới danh sách...',
        'dxScrollView-pulledDownText': 'Thả chuột ra để làm mới danh sách...',
        'dxScrollView-refreshingText': 'Đang tải lại...',
        'dxScrollView-reachBottomText': 'Đang tải...',
        'dxDateBox-simulatedDataPickerTitleTime': 'Chọn thời gian',
        'dxDateBox-simulatedDataPickerTitleDate': 'Chọn ngày',
        'dxDateBox-simulatedDataPickerTitleDateTime': 'Chọn ngày giờ',
        'dxDateBox-validation-datetime': 'Giá trị phải là ngày hoặc giờ',
        'dxFileUploader-selectFile': 'Chọn tập tin',
        'dxFileUploader-dropFile': 'Hoặc kéo thả file vào đây',
        'dxFileUploader-bytes': 'bytes',
        'dxFileUploader-kb': 'kb',
        'dxFileUploader-Mb': 'Mb',
        'dxFileUploader-Gb': 'Gb',
        'dxFileUploader-upload': 'Tải lên',
        'dxFileUploader-uploaded': 'Đã tải lên',
        'dxFileUploader-readyToUpload': 'Sẵn sàng tải lên',
        'dxFileUploader-uploadFailedMessage': 'Tải lên thất bại',
        'dxRangeSlider-ariaFrom': 'Từ',
        'dxRangeSlider-ariaTill': 'Cho đến khi',
        'dxSwitch-onText': 'BẬT',
        'dxSwitch-offText': 'TẮT',
        'dxForm-optionalMark': 'không bắt buộc',
        'dxForm-requiredMessage': '{0} là bắt buộc',
        'dxNumberBox-invalidValueMessage': 'Giá trị phải là số',
        'dxDataGrid-columnChooserTitle': 'Chọn cột hiển thị',
        'dxDataGrid-columnChooserEmptyText': 'Kéo một cột vào đây để ẩn nó',
        'dxDataGrid-groupContinuesMessage': 'Tiếp tục ở trang tiếp theo',
        'dxDataGrid-groupContinuedMessage': 'Tiếp tục từ trang trước',
        'dxDataGrid-groupHeaderText': 'Gộp nhóm theo cột này',
        'dxDataGrid-ungroupHeaderText': 'Gộp nhóm',
        'dxDataGrid-ungroupAllText': 'Bỏ tất cả gộp nhóm',
        'dxDataGrid-editingEditRow': 'Sửa',
        'dxDataGrid-editingSaveRowChanges': 'Lưu lại',
        'dxDataGrid-editingCancelRowChanges': 'Hủy',
        'dxDataGrid-editingDeleteRow': 'Xóa',
        'dxDataGrid-editingUndeleteRow': 'Bỏ xóa',
        'dxDataGrid-editingConfirmDeleteMessage': 'Bạn có chắc chắn muốn xóa dữ liệu này không?',
        'dxDataGrid-validationCancelChanges': 'Hủy các thay đổi',
        'dxDataGrid-groupPanelEmptyText': 'Kéo tiêu đề cột vào đây để nhóm theo cột đó',
        'dxDataGrid-noDataText': 'Không có dữ liệu',
        'dxDataGrid-searchPanelPlaceholder': 'Tìm kiếm...',
        'dxDataGrid-filterRowShowAllText': '(Tất cả)',
        'dxDataGrid-filterRowResetOperationText': 'Đặt lại',
        'dxDataGrid-filterRowOperationEquals': 'Bằng',
        'dxDataGrid-filterRowOperationNotEquals': 'Không bằng',
        'dxDataGrid-filterRowOperationLess': 'Nhỏ hơn',
        'dxDataGrid-filterRowOperationLessOrEquals': 'Nhỏ hơn hoặc bằng',
        'dxDataGrid-filterRowOperationGreater': 'Lớn hơn',
        'dxDataGrid-filterRowOperationGreaterOrEquals': 'Lớn hơn hoặc bằng',
        'dxDataGrid-filterRowOperationStartsWith': 'Bắt đầu với chữ',
        'dxDataGrid-filterRowOperationContains': 'Có chứa chữ',
        'dxDataGrid-filterRowOperationNotContains': 'Không chứa chữ',
        'dxDataGrid-filterRowOperationEndsWith': 'Kết thúc bằng',
        'dxDataGrid-filterRowOperationBetween': 'Trong khoảng',
        'dxDataGrid-filterRowOperationBetweenStartText': 'Bắt đầu bằng',
        'dxDataGrid-filterRowOperationBetweenEndText': 'Kết thúc bằng',
        'dxDataGrid-applyFilterText': 'Áp dụng bộ lọc',
        'dxDataGrid-trueText': 'đúng',
        'dxDataGrid-falseText': 'sai',
        'dxDataGrid-sortingAscendingText': 'Sắp xếp tăng dần',
        'dxDataGrid-sortingDescendingText': 'Sắp xếp giảm dần',
        'dxDataGrid-sortingClearText': 'Bỏ sắp xếp',
        'dxDataGrid-editingSaveAllChanges': 'Lưu toàn bộ thay đổi',
        'dxDataGrid-editingCancelAllChanges': 'Hủy bỏ các thay đổi',
        'dxDataGrid-editingAddRow': 'Thêm dữ liệu',
        'dxDataGrid-summaryMin': 'Tối thiểu: {0}',
        'dxDataGrid-summaryMinOtherColumn': 'Tối thiểu của {1} là {0}',
        'dxDataGrid-summaryMax': 'Tối đa: {0}',
        'dxDataGrid-summaryMaxOtherColumn': 'Tối đa của {1} là {0}',
        'dxDataGrid-summaryAvg': 'Avg: {0}',
        'dxDataGrid-summaryAvgOtherColumn': 'Trung bình của {1} là {0}',
        'dxDataGrid-summarySum': 'Sum: {0}',
        'dxDataGrid-summarySumOtherColumn': 'Tổng của {1} là {0}',
        'dxDataGrid-summaryCount': 'Đếm: {0}',
        'dxDataGrid-columnFixingFix': 'Cố định cột',
        'dxDataGrid-columnFixingUnfix': 'Bỏ cố định cột',
        'dxDataGrid-columnFixingLeftPosition': 'Cố định bên trái',
        'dxDataGrid-columnFixingRightPosition': 'Cố định bên phải',
        'dxDataGrid-exportTo': 'Xuất',
        'dxDataGrid-exportToExcel': 'Xuất sang tệp Excel',
        'dxDataGrid-excelFormat': 'Tệp Excel',
        'dxDataGrid-selectedRows': 'Các hàng đã chọn',
        'dxDataGrid-exportSelectedRows': 'Xuất các hàng đã chọn',
        'dxDataGrid-exportAll': 'Xuất tất cả dữ liệu',
        'dxDataGrid-headerFilterEmptyValue': '(Trống)',
        'dxDataGrid-headerFilterOK': 'OK',
        'dxDataGrid-headerFilterCancel': 'Hủy bỏ',
        'dxDataGrid-ariaColumn': 'Cột',
        'dxDataGrid-ariaValue': 'Giá trị',
        'dxDataGrid-ariaFilterCell': 'Ô lọc',
        'dxDataGrid-ariaCollapse': 'Thu gọn',
        'dxDataGrid-ariaExpand': 'Mở rộng',
        'dxDataGrid-ariaDataGrid': 'Bảng dữ liệu',
        'dxDataGrid-ariaSearchInGrid': 'Tìm kiếm trong bảng dữ liệu',
        'dxDataGrid-ariaSelectAll': 'Chọn tất cả',
        'dxDataGrid-ariaSelectRow': 'Chọn hàng',
        'dxDataGrid-filterBuilderPopupTitle': 'Trình tạo bộ lọc',
        'dxDataGrid-filterPanelCreateFilter': 'Tạo bộ lọc',
        'dxDataGrid-filterPanelClearFilter': 'Xóa',
        'dxDataGrid-filterPanelFilterEnabledHint': 'Bật bộ lọc',
        'dxTreeList-ariaTreeList': 'Danh sách cây',
        'dxTreeList-editingAddRowToNode': 'Thêm',
        'dxPager-infoText': 'Trang {0} trong số {1} ({2} mục)',
        'dxPager-pagesCountText': 'của',
        'dxPivotGrid-grandTotal': 'Tổng cộng',
        'dxPivotGrid-total': 'Tổng số {0}',
        'dxPivotGrid-fieldChooserTitle': 'Chọn trường',
        'dxPivotGrid-showFieldChooser': 'Hiển thị trình chọn trường',
        'dxPivotGrid-expandAll': 'Mở rộng tất cả',
        'dxPivotGrid-collapseAll': 'Thu gọn tất cả',
        'dxPivotGrid-sortColumnBySummary': 'Sắp xếp "{0}" theo cột này',
        'dxPivotGrid-sortRowBySummary': 'Sắp xếp "{0}" bởi hàng này',
        'dxPivotGrid-removeAllSorting': 'Xóa tất cả sắp xếp',
        'dxPivotGrid-dataNotAvailable': 'N/A',
        'dxPivotGrid-rowFields': 'Xóa tất cả các trường sắp xếp',
        'dxPivotGrid-columnFields': 'Các trường cột',
        'dxPivotGrid-dataFields': 'Trường dữ liệu',
        'dxPivotGrid-filterFields': 'Bộ lọc',
        'dxPivotGrid-allFields': 'Tất cả các trường',
        'dxPivotGrid-columnFieldArea': 'Thả các trường ở đây',
        'dxPivotGrid-dataFieldArea': 'Thả trường dữ liệu tại đây',
        'dxPivotGrid-rowFieldArea': 'Thả các trường hàng tại đây',
        'dxPivotGrid-filterFieldArea': 'Thả các trường lọc tại đây',
        'dxScheduler-editorLabelTitle': 'Đối tượng',
        'dxScheduler-editorLabelStartDate': 'Ngày bắt đầu',
        'dxScheduler-editorLabelEndDate': 'Ngày kết thúc',
        'dxScheduler-editorLabelDescription': 'Mô tả',
        'dxScheduler-editorLabelRecurrence': 'Lặp lại',
        'dxScheduler-openAppointment': 'Mở cuộc hẹn',
        'dxScheduler-recurrenceNever': 'Never',
        'dxScheduler-recurrenceDaily': 'Daily',
        'dxScheduler-recurrenceWeekly': 'Weekly',
        'dxScheduler-recurrenceMonthly': 'Monthly',
        'dxScheduler-recurrenceYearly': 'Yearly',
        'dxScheduler-recurrenceEvery': 'Every',
        'dxScheduler-recurrenceEnd': 'End repeat',
        'dxScheduler-recurrenceAfter': 'After',
        'dxScheduler-recurrenceOn': 'On',
        'dxScheduler-recurrenceRepeatDaily': 'day(s)',
        'dxScheduler-recurrenceRepeatWeekly': 'week(s)',
        'dxScheduler-recurrenceRepeatMonthly': 'month(s)',
        'dxScheduler-recurrenceRepeatYearly': 'year(s)',
        'dxScheduler-switcherDay': 'Day',
        'dxScheduler-switcherWeek': 'Week',
        'dxScheduler-switcherWorkWeek': 'Work Week',
        'dxScheduler-switcherMonth': 'Month',
        'dxScheduler-switcherAgenda': 'Agenda',
        'dxScheduler-switcherTimelineDay': 'Timeline Day',
        'dxScheduler-switcherTimelineWeek': 'Timeline Week',
        'dxScheduler-switcherTimelineWorkWeek': 'Timeline Work Week',
        'dxScheduler-switcherTimelineMonth': 'Timeline Month',
        'dxScheduler-recurrenceRepeatOnDate': 'on date',
        'dxScheduler-recurrenceRepeatCount': 'occurrence(s)',
        'dxScheduler-allDay': 'All day',
        'dxScheduler-confirmRecurrenceEditMessage': 'Do you want to edit only this appointment or the whole series?',
        'dxScheduler-confirmRecurrenceDeleteMessage': 'Do you want to delete only this appointment or the whole series?',
        'dxScheduler-confirmRecurrenceEditSeries': 'Edit series',
        'dxScheduler-confirmRecurrenceDeleteSeries': 'Delete series',
        'dxScheduler-confirmRecurrenceEditOccurrence': 'Edit appointment',
        'dxScheduler-confirmRecurrenceDeleteOccurrence': 'Delete appointment',
        'dxScheduler-noTimezoneTitle': 'No timezone',
        'dxScheduler-moreAppointments': '{0} more',
        'dxCalendar-todayButtonText': 'Today',
        'dxCalendar-ariaWidgetName': 'Calendar',
        'dxColorView-ariaRed': 'Red',
        'dxColorView-ariaGreen': 'Green',
        'dxColorView-ariaBlue': 'Blue',
        'dxColorView-ariaAlpha': 'Transparency',
        'dxColorView-ariaHex': 'Color code',
        'dxTagBox-selected': '{0} selected',
        'dxTagBox-allSelected': 'All selected ({0})',
        'dxTagBox-moreSelected': '{0} more',
        'vizExport-printingButtonText': 'Print',
        'vizExport-titleMenuText': 'Xuất / In',
        'vizExport-exportButtonText': '{0} file',
        'dxFilterBuilder-and': 'Và',
        'dxFilterBuilder-or': 'Hoặc',
        'dxFilterBuilder-notAnd': 'Không Và',
        'dxFilterBuilder-notOr': 'Không Hoặc',
        'dxFilterBuilder-addCondition': 'Thêm điều kiện',
        'dxFilterBuilder-addGroup': 'Thêm nhóm',
        'dxFilterBuilder-enterValueText': '<nhập giá trị>',
        'dxFilterBuilder-filterOperationEquals': 'Bằng',
        'dxFilterBuilder-filterOperationNotEquals': 'Không bằng',
        'dxFilterBuilder-filterOperationLess': 'Nhỏ hơn',
        'dxFilterBuilder-filterOperationLessOrEquals': 'Nhỏ hơn hoặc bằng',
        'dxFilterBuilder-filterOperationGreater': 'Lớn hơn',
        'dxFilterBuilder-filterOperationGreaterOrEquals': 'Lớn hơn hoặc bằng',
        'dxFilterBuilder-filterOperationStartsWith': 'Bắt đầu với',
        'dxFilterBuilder-filterOperationContains': 'Có chứa chữ',
        'dxFilterBuilder-filterOperationNotContains': 'Không chứa chữ',
        'dxFilterBuilder-filterOperationEndsWith': 'Kết thúc bằng',
        'dxFilterBuilder-filterOperationIsBlank': 'Trống',
        'dxFilterBuilder-filterOperationIsNotBlank': 'Không trống',
        'dxFilterBuilder-filterOperationBetween': 'Trong khoảng',
        'dxFilterBuilder-filterOperationAnyOf': 'Thuộc bất kỳ',
        'dxFilterBuilder-filterOperationNoneOf': 'Không thuộc trong số'
      }
    })
    locale(navigator.language || navigator['browserLanguage'])


  }
}

import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire//auth';
import { AngularFireDatabase } from '@angular/fire//database';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'core-cart-header-icon-base',
  template: ''
})
export class CartHeaderIconBaseComponent {
  globalCart: any;
  user: Observable<firebase.User>;
  cartItems = 0;

  constructor(
    public globalService: GlobalService,
    public localCacheService: LocalCacheService,
    public afAuth: AngularFireAuth,
    public db: AngularFireDatabase) {
    this.user = afAuth.authState;

    globalService.cart.subscribe((cart) => {
      this.globalCart = cart;
      if (this.globalCart) {
        const cartArray = (<any>Object).values(this.globalCart);
        this.cartItems = cartArray.reduce((sum, cartItem) => sum + cartItem.quantity, 0);
      } else {
        this.cartItems = 0;
      }
    });

  }

}

import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'core-admin-repeat-time-type',
  templateUrl: './repeat-time-type.component.html',
  styleUrls: ['./repeat-time-type.component.scss']
})
export class RepeatTimeTypeComponent extends FieldArrayType implements OnInit {
  constructor() {
    super();
  }
  ngOnInit() {

  }

  addTime() {
    this.add();
    if (this.model.length >= 2) {
      const old = this.model[this.model.length - 2];
      this.model[this.model.length - 1].Start = old.End;
      this.model[this.model.length - 1].End = (new Date(new Date(old.End).getTime() + (2 * 60 * 60 * 1000))).toISOString();
    } else {
      const morning = new Date((new Date()).setHours(6, 0, 0, 0));
      this.model[this.model.length - 1].Start = morning.toISOString();
      this.model[this.model.length - 1].End = (new Date(new Date(morning).setHours(11, 0, 0, 0))).toISOString();
    }
    this.add();
    this.remove(this.model.length - 1);
  }

  // getD(date) {
  //   console.log((new Date(date)).getHours() + ":" + (new Date(date)).getMinutes());
  // }

}

import { AfterContentInit, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { RemoveWrapperDirective } from './remove-wrapper.directive';

@Component({
  selector: 'core-formly-horizontal-wrapper',
  template: `
    <div class="form-group row">
        <label [attr.for]="id" class="col-md-3 control-label" *ngIf="to.label" style="text-align: end;line-height: 40px;">
          {{ to.label }}
          <ng-container *ngIf="to.required">*</ng-container>
        </label>
        <div class="col-md-9">
          <ng-template #fieldComponent></ng-template>
        </div>
        <div *ngIf="showError">
          <formly-validation-message [field]="field"></formly-validation-message>
        </div>
    </div>
  `,
  styles: [
    `

    `
  ]
})
export class FormlyHorizontalWrapperComponent extends FieldWrapper
  implements AfterContentInit, OnInit {

  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true })
  fieldComponent!: ViewContainerRef;
  @ViewChild(RemoveWrapperDirective, { static: true }) directive;

  ngOnInit(): void {
    // this.formControl.valueChanges.subscribe(val => {
    //   const a = this.to.order;
    //   const b = this.model;
    //   debugger
    // });
  }

  ngAfterContentInit() {
    if (this.directive) {
      this.directive.removeParentTag();
    }
  }
}

import { Injectable } from '@angular/core';

declare var document: any;

@Injectable({
  providedIn: 'root'
})

export class DynamicScriptAndStyleLoaderService {

  private scripts: any = [];
  private styles: any = [];

  constructor() {

  }

  loadAllScripts(...scriptUrls: string[]) {
    scriptUrls.forEach((url: any) => {
      this.scripts.push({
        loaded: false,
        url: url
      });
    });

    const promises: any[] = [];
    scriptUrls.forEach((url) => promises.push(this.loadScript(url)));
    return Promise.all(promises);
  }

  loadScript(url: string) {
    return new Promise((resolve, reject) => {
      const cscript = this.scripts.find(a => a.url === url);
      if (cscript && !cscript.loaded) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = cscript.url;
        if (script.readyState) {  //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              cscript.loaded = true;
              resolve({ url: url, loaded: true });
            }
          };
        } else {  //Others
          script.onload = () => {
            cscript.loaded = true;
            resolve({ url: url, loaded: true });
          };
        }
        script.onerror = (error: any) => resolve({ script: url, loaded: false });
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ url: url, loaded: true });
      }
    });
  }


  loadAllStyles(...styleUrls: string[]) {
    styleUrls.forEach((url: any) => {
      this.styles.push({
        loaded: false,
        url: url
      });
    });

    const promises: any[] = [];
    styleUrls.forEach((url) => promises.push(this.loadStyle(url)));
    return Promise.all(promises);
  }

  loadStyle(url: string) {
    return new Promise((resolve, reject) => {
      const cstyle = this.styles.find(a => a.url === url);
      if (!cstyle.loaded) {
        const style = document.createElement('link');
        style.href = cstyle.url;
        style.rel = 'stylesheet';
        style.type = 'text/css';
        if (style.readyState) {  //IE
          style.onreadystatechange = () => {
            if (style.readyState === "loaded" || style.readyState === "complete") {
              style.onreadystatechange = null;
              cstyle.loaded = true;
              resolve({ url: url, loaded: true });
            }
          };
        } else {  //Others
          style.onload = () => {
            cstyle.loaded = true;
            resolve({ url: url, loaded: true });
          };
        }
        style.onerror = (error: any) => resolve({ url: url, loaded: false });
        document.getElementsByTagName('head')[0].appendChild(style);
      } else {
        resolve({ url: url, loaded: true });
      }
    });
  }



  removeAll_Scripts() {
    const div = document.getElementsByTagName('head')[0];
    const scripts = div.getElementsByTagName('script');
    let i = scripts.length;
    while (i--) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }
  }


  removeAll_Styles() {
    const div = document.getElementsByTagName('head')[0];
    const links = div.getElementsByTagName('link');
    let j = links.length;
    while (j--) {
      links[j].parentNode.removeChild(links[j]);
    }
  }


}
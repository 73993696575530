import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CartListBaseComponent } from '@core/base-frontend/src/cart-base/cart-list-base.component';
import { OrderBaseComponent } from '@core/base-frontend/src/order-base/order-base.component';
import { InvoiceModule } from '@core/utils/src/components/invoice/invoice.module';
import { FormlyTypeModule } from '@core/utils/src/formly-type/formly-type.module';
import { UtilsModule } from '@core/utils/src/utils.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SectionModule } from '../common/section/section.module';
import { CartListComponent } from './cart-list/cart-list.component';
import { OrderComponent } from './order/order.component';
import { OrdersComponent } from './orders.component';

const routes: Routes = [
  { path: '', component: CartListComponent },
  { path: 'mine/:key', component: OrderComponent },
  { path: 'mine', component: OrdersComponent },
]

@NgModule({
  declarations: [
    CartListBaseComponent,
    CartListComponent,
    OrdersComponent,
    OrderComponent,
    OrderBaseComponent,
  ],
  imports: [
    CommonModule,
    InvoiceModule,
    FormlyTypeModule,
    UtilsModule,
    SectionModule,
    FormsModule,
    RouterModule.forChild(routes),
    TooltipModule.forRoot()
  ],
  exports: [RouterModule]
})
export class OrderModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageBaseComponent } from '@core/base-frontend/src/common/page-base.component';
import { UtilsModule } from '@core/utils/src/utils.module';
import { PageComponent } from './page.component';

const routes: Routes = [
  { path: '', component: PageComponent },
  { path: ':url', component: PageComponent }
]

@NgModule({
  declarations: [
    PageComponent,
    PageBaseComponent
  ],
  imports: [
    CommonModule,
    UtilsModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class PageModule { }

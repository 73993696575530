import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { CropImageComponent } from './crop-image/crop-image.component';
import { GridImagesComponent } from './grid-images/grid-images.component';
import { ListUploadComponent } from './list-upload/list-upload.component';
@NgModule({
  declarations: [
    CropImageComponent,
    GridImagesComponent,
    ListUploadComponent
  ],
  exports: [
    CropImageComponent,
    GridImagesComponent,
    ListUploadComponent,
    ImageCropperModule,
    ModalModule,
    DataViewModule,
    DropdownModule,
    TableModule,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ModalModule,
    ImageCropperModule,
    DropdownModule,
    DataViewModule,
    TableModule,
  ],
  entryComponents: [GridImagesComponent, ListUploadComponent],
  providers: [BsModalRef]
})
export class UploadImageModule { }

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationModalComponent } from './components/confirmation/confirmation-modal.component';
import { DestroyableComponent } from './components/destroyable/destroyable.component';
import { ArraySortPipe } from './pipes/array-sort.pipe';
import { GetDayPipe } from './pipes/dayjs/get-day.pipe';
import { GetMonthPipe } from './pipes/dayjs/get-month.pipe';
import { GetYearPipe } from './pipes/dayjs/get-year.pipe';
import { TimeAgoPipe } from './pipes/dayjs/time-ago.pipe';
import { FilterGroupInfoPipe } from './pipes/filter-group-info.pipe';
import { GetKeyPipe } from './pipes/get-key.pipe';
import { GetProductImagePipe } from './pipes/get-product-image.pipe';
import { GetThumbPipe } from './pipes/get-thumb.pipe';
// Pipes
import { GetUserPipe } from './pipes/getUser.pipe';
import { ObjectCountPipe } from './pipes/object-count.pipe';
import { RandomSlicePipe } from './pipes/random-slice.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { TruncateHtmlPipe } from './pipes/truncate-html.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { FacebookPostComponent } from './shared/facebook-posts/facebook-posts.component';
const pipes = [
  GetUserPipe,
  GetKeyPipe,
  ObjectCountPipe,
  SafePipe,
  SafeHtmlPipe,
  SortPipe,
  RandomSlicePipe,
  TruncatePipe,
  TruncateHtmlPipe,
  SearchPipe,
  FilterGroupInfoPipe,
  DestroyableComponent,
  TimeAgoPipe,
  GetThumbPipe,
  GetProductImagePipe,
  GetDayPipe,
  GetMonthPipe,
  GetYearPipe,
  ConfirmationModalComponent,
  FacebookPostComponent,
  ArraySortPipe
];


@NgModule({
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      // closeButton: true,
      // progressAnimation: 'decreasing'
    }),
    ModalModule.forRoot()
  ],
  declarations: [
    ...pipes,
  ],
  exports: [
    ...pipes
  ],
  entryComponents: [ConfirmationModalComponent]
})
export class UtilsModule { }

import { Component } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire//database';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DestroyableComponent } from '@core/utils/src/components/destroyable/destroyable.component';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import 'firebase/auth';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'core-home-base',
  template: ''
})
export class HomeBaseComponent extends DestroyableComponent {
  public posts: Observable<any>;
  public categoryProducts: any;
  constructor(
    public db: AngularFireDatabase,
    public toastr: ToastrService,
    public globalService: GlobalService,
    public localCacheService: LocalCacheService,
    public router: Router,
    public title: Title,
    public meta: Meta,
  ) {
    super();
    this.posts = this.localCacheService.getPostsPublishedAsObservable(6);
    this.categoryProducts = localCacheService.getRootCategoriesWithAllProducts().map(a => ({ ...a, products: a.products.filter(b => b.showHome === true).slice(0, 8) }));

    this.localCacheService.GroupInfo$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(list => {
        this.title.setTitle('Trang chủ' + ' | ' + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_NGAN_GON) + '');

        this.meta.updateTag({ content: 'Trang chủ ' + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_DAY_DU) }, "name='description'");
      });
  }


}



export class CommonFunctionsService {

  /*  CÁC BIẾN DÙNG CHUNG CỐ ĐỊNH  */


  public DUNG_CHUNG = 'dung-chung';
  public TRANG_CHU = 'trang-chu';

  public SDT1 = 'so-dien-thoai-1';
  public SDT2 = 'so-dien-thoai-2';
  public DIA_CHI = 'dia-chi-cong-ty';
  public EMAIL = 'dia-chi-email';
  public ZALO = 'chat-zalo';
  public MESSENGER = 'chat-messenger';
  public HOTLINE = 'hotline';
  public GIO_MO_CUA = 'gio-mo-cua';
  public TEN_CONG_TY_DAY_DU = 'ten-cong-ty-day-du';
  public TEN_CONG_TY_NGAN_GON = 'ten-cong-ty-ngan-gon';
  public LOGO = 'logo-cong-ty';
  public SLOGAN = 'mo-ta-cong-ty-slogan';
  public BANNER = 'banner-trang-chu';
  public ATM = 'cac-loai-the-co-the-thanh-toan';
  public KHACH_LE_MUA_HANG = 'cho-phep-khach-le-mua-hang-khong-can-dang-nhap';
  public TRANG_THAI_DON_HANG = 'cac-trang-thai-cua-don-hang';
  public DANH_SAC_HDV = 'danh-sach-hdv';
  public LOGO_DOI_TAC = 'logo-doi-tac';
  public CON_SO = 'cac-con-so-ve-chung-toi';
  public KENH_YOUTUBE = 'kenh-youtube';
  public TRANG_FACEBOOK = 'trang-facebook';
  public DICH_VU_CUA_CONG_TY = 'cac-dich-vu-cua-cong-ty';
  public KHACH_HANG_NHAN_XET = 'khach-hang-noi-ve-chung-toi';
  public LOAI_BAI_VIET = 'loai-bai-viet';
  public TEAMBUILDIING_TRONGNGAY = 'TOUR KHỞI HÀNH HÀNG NGÀY';
  public KHACHLE = 'Du lịch cuối tuần';



  public TEAMBUILDING = 'teambuilding';
  public XEDULICH = 'Xe du lịch';
  public SUKIEN = 'Sự kiện';


  /*  END CÁC BIẾN DÙNG CHUNG CỐ ĐỊNH  */


  public isArray(obj: any) {
    return Object.prototype.toString.call(obj) === '[object Array]';
  }

  public isObjectNotArray(obj: any) {
    return obj && !Array.isArray(obj) && typeof obj === 'object';
  }

  public isObject(obj: any) {
    return typeof obj === 'object';
  }

  public isDate(obj: any) {
    return Object.prototype.toString.call(obj) === '[object Date]';
  }

  public isString(obj: any) {
    return Object.prototype.toString.call(obj) === '[object String]';
  }


  public getValue(value) {
    return typeof value === 'string' ? value.toUpperCase() : value;
  }

  public hashCode(input: string) {
    let hash = 0, i, chr;
    if (input.length === 0) return hash;
    for (i = 0; i < input.length; i++) {
      chr = input.charCodeAt(i);
      // tslint:disable-next-line: no-bitwise
      hash = ((hash << 5) - hash) + chr;
      // tslint:disable-next-line: no-bitwise
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  public makeTreeWithChildren(arr) {
    const tree = [], mappedArr = {};
    let arrElem, mappedElem;
    const prop = 'sort_order';
    arr.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    // First map the nodes of the array to an object -> create a hash table.
    for (let i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.key] = arrElem;
      mappedArr[arrElem.key]['children'] = [];
    }

    for (const key in mappedArr) {
      if (mappedArr.hasOwnProperty(key)) {
        mappedElem = mappedArr[key];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.parent_id && mappedArr[mappedElem['parent_id']]) {
          mappedArr[mappedElem['parent_id']]['children'].push(mappedElem);
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }




  public makeTreeWithChildrenPrimeNg(arr, sortFiled) {
    const tree = [], mappedArr = {};
    let arrElem, mappedElem;
    const prop = sortFiled;
    arr.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
    // First map the nodes of the array to an object -> create a hash table.
    for (let i = 0, len = arr.length; i < len; i++) {
      arrElem = arr[i];
      mappedArr[arrElem.key] = arrElem;
      mappedArr[arrElem.key]['label'] = arrElem.name;
      mappedArr[arrElem.key]['data'] = arrElem.slug;
      mappedArr[arrElem.key]['expanded'] = true;
      mappedArr[arrElem.key]['children'] = [];
    }

    for (const key in mappedArr) {
      if (mappedArr.hasOwnProperty(key)) {
        mappedElem = mappedArr[key];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.parent_id && mappedArr[mappedElem['parent_id']]) {
          mappedArr[mappedElem['parent_id']]['children'].push(mappedElem);
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  }


  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

}
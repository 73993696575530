import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'core-devex-tree-view-formly-type',
  templateUrl: './devex-tree-view-formly-type.component.html',
  styleUrls: ['./devex-tree-view-formly-type.component.scss']
})
export class DevexTreeViewFormlyTypeComponent extends FieldType implements OnInit {
  categories: any;
  checkedItems = [];


  constructor() {
    super();
  }

  ngOnInit() {
    this.categories = this.to.options;
  }

  emitChangeValue(items: any) {
    if (items && items.length > 0) {
      if (!this.model.CategoryIds) this.model.CategoryIds = {};
      items.forEach(item => {
        this.model.CategoryIds[item.key] = item.isSelected ? item.isSelected : false;
      });
    }

  }
}

import { Component, Input } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire//database';
import { SectionBaseComponent } from '@core/base-frontend/src/home-base/section-base.component';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'common-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent extends SectionBaseComponent {

  @Input() sectionName: string;
  @Input() products: any[];
  @Input() productClass: string;

  constructor(
    public db: AngularFireDatabase,
    public toastr: ToastrService,
    public localCacheService: LocalCacheService,
    public globalService: GlobalService
  ) {
    super(db, toastr, localCacheService, globalService);
  }


}

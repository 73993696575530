import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FrontComponent } from './front/front.component';
import { HomeComponent } from './front/home/home.component';


const routes: Routes = [
  {
    path: '', component: FrontComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'about', loadChildren: () => import('./front/common/about/about.module').then(m => m.AboutModule) },
      { path: 'contact', loadChildren: () => import('./front/common/contact/contact.module').then(m => m.ContactModule) },
      { path: 'blog', loadChildren: () => import('./front/blog/blog.module').then(m => m.BlogModule) },
      { path: 'order', loadChildren: () => import('./front/orders/order.module').then(m => m.OrderModule) },
      { path: 'product', loadChildren: () => import('./front/products/product.module').then(m => m.ProductModule) },
      { path: 'page', loadChildren: () => import('./front/common/page/page.module').then(m => m.PageModule) },
    ]
  },
  { path: 'admin', loadChildren: () => import('../../../../libs/base-admin/src/base-admin.module').then(m => m.BaseAdminModule) },
  { path: 'login', loadChildren: () => import('../../../../libs/base-frontend/src/login/login.module').then(m => m.LoginModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, ElementRef, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire//auth';
import { AngularFireDatabase } from '@angular/fire//database';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DestroyableComponent } from '@core/utils/src/components/destroyable/destroyable.component';
import { CartService } from '@core/utils/src/services/common/cart.service';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import { Gallery, GalleryItem, ImageItem, ImageSize, ThumbnailsPosition } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';
import { ToastrService } from 'ngx-toastr';
import { map, takeUntil } from 'rxjs/operators';

declare function StartSchedule(): any;
declare var FB: any;
declare var $: any;


@Component({
  selector: 'core-product-base',
  template: ''
})
export class ProductBaseComponent extends DestroyableComponent implements OnInit {
  categoryProducts: any[];
  relatedProducts: any;

  constructor(
    public db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    public route: ActivatedRoute,
    public router: Router,
    public globalService: GlobalService,
    public toastr: ToastrService,
    public localCacheService: LocalCacheService,
    public localCart: CartService,
    public title: Title,
    public meta: Meta,
    public sanitizer: DomSanitizer,
    public elRef: ElementRef,
    public gallery: Gallery,
    public lightbox: Lightbox
  ) {
    super();
    this.model = {};
  }
  product: any;
  model: any;
  images: GalleryItem[] = [];
  productFeatures = {};

  ngOnInit() {

    this.categoryProducts = this.localCacheService.getRootCategoriesWithAllProducts().map(a => ({ ...a, products: a.products.filter(b => b.showHome === true) }));

    this.route.params.subscribe((params: Params) => {
      this.localCacheService.getProductsPublishedAsObservable()
        .pipe(map(a => a.filter(b => b.url === params.url)))
        .subscribe(p => {
          if (p && p.length > 0) {
            this.product = p[0];
            this.relatedProducts = this.categoryProducts.find(a => a.products.find(b => b.key === this.product.key));

            if (this.localCacheService.projectName === 'hittravel') {
              const g = setInterval(() => {
                if (typeof $ !== 'undefined' && typeof (window as any).StartSchedule !== 'undefined') {
                  clearInterval(g);
                  setTimeout(() => {
                    StartSchedule();
                  }, 500);
                }
              }, 100);
            }

            if (this.product.features) {
              this.productFeatures = this.localCacheService.genFeatureTable(this.product);
            }

            if (this.product.thumbnails && this.product.thumbnails.length > 0) {
              this.images = this.product.thumbnails.map(item => {
                return new ImageItem({
                  src: item.imageUrl,
                  thumb: item.imageUrl
                });
              });
              const lightboxRef = this.gallery.ref('lightbox');
              lightboxRef.setConfig({
                imageSize: ImageSize.Contain,
                thumbPosition: ThumbnailsPosition.Bottom,
                gestures: true,
                loop: false
              });
              lightboxRef.load(this.images);
            };

            const b = setInterval(() => {
              if (document.getElementById('facebookCommentID') && typeof FB !== 'undefined') {
                clearInterval(b);
                document.getElementById('facebookCommentID').innerHTML = '<div class="fb-comments" data-href="' + document.location + '" data-num-posts="20" data-width="1000"></div>';
                FB.XFBML.parse(document.getElementById('facebookCommentID'));
              }
            }, 100);
            const c = setInterval(() => {
              if (document.getElementById('fbsharebuttonID') && typeof FB !== 'undefined') {
                clearInterval(c);
                document.getElementById('fbsharebuttonID').innerHTML = '<div class="fb-share-button" data-href="' + document.location.href + '" data-layout="button_count" data-size="small"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">Chia sẻ</a></div>';
                FB.XFBML.parse(document.getElementById('fbsharebuttonID'));
                document.getElementById('fbsharebuttonID')
              }
            }, 100);


          } else {
            this.product = {
              title: 'Không tìm thấy sản phẩm',
              body: '',
              quantity: 0
            }
          }
        });
    });

    this.localCacheService.GroupInfo$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(list => {
        this.title.setTitle(this.product.title + ' | ' + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_NGAN_GON) + '');

        this.meta.updateTag({ content: this.product.title + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_DAY_DU) }, "name='description'");
      });

    let doc = new DOMParser().parseFromString(this.product?.description?.replace(/<[^>]*>/g), 'text/html').documentElement.textContent;
    if (doc) { doc = doc.replace(/undefined/g, '').replace(/\n/g, '') }
    this.meta.updateTag({ content: doc }, "name='description'");
  }

}

import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire//database';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfirmationModalComponent } from '../../components/confirmation/confirmation-modal.component';
import { CartService } from './cart.service';
import { CommonFunctionsService } from './common-functions.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService extends CommonFunctionsService {

  public user = new BehaviorSubject(null);
  public admin = new BehaviorSubject(null);
  public cart = new BehaviorSubject({});
  public order = new BehaviorSubject({});
  public searchTerm = new BehaviorSubject('');
  public projectName: string;

  constructor(
    public db: AngularFireDatabase,
    public bsModalService: BsModalService,
    public localCart: CartService,
    private toastr: ToastrService
  ) {
    super();
  }

  public slugify(text: string) {
    if (text) {
      text = text.toString().toLowerCase().trim();

      const sets = [
        { to: 'a', from: '[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
        { to: 'c', from: '[ÇĆĈČ]' },
        { to: 'd', from: '[ÐĎĐÞ]' },
        { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
        { to: 'g', from: '[ĜĞĢǴ]' },
        { to: 'h', from: '[ĤḦ]' },
        { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
        { to: 'j', from: '[Ĵ]' },
        { to: 'ij', from: '[Ĳ]' },
        { to: 'k', from: '[Ķ]' },
        { to: 'l', from: '[ĹĻĽŁ]' },
        { to: 'm', from: '[Ḿ]' },
        { to: 'n', from: '[ÑŃŅŇ]' },
        { to: 'o', from: '[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
        { to: 'oe', from: '[Œ]' },
        { to: 'p', from: '[ṕ]' },
        { to: 'r', from: '[ŔŖŘ]' },
        { to: 's', from: '[ßŚŜŞŠ]' },
        { to: 't', from: '[ŢŤ]' },
        { to: 'u', from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
        { to: 'w', from: '[ẂŴẀẄ]' },
        { to: 'x', from: '[ẍ]' },
        { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
        { to: 'z', from: '[ŹŻŽ]' },
        { to: '-', from: '[·/_,:;\']' }
      ];
      sets.forEach(set => {
        text = text.replace(new RegExp(set.from, 'gi'), set.to);
      });
      text = text.toString().toLowerCase()
        .replace(/\s+/g, '-')         // Replace spaces with -
        .replace(/&/g, '-and-')       // Replace & with 'and'
        .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
        .replace(/\--+/g, '-')        // Replace multiple - with single -
        .replace(/^-+/, '')           // Trim - from start of text
        .replace(/-+$/, '')          // Trim - from end of text
        .replace(/-/g, '-');
      return text;
    }
  }

  public convertObjectToArray(obj) {
    const ar = [];
    for (const [key, value] of Object.entries(obj)) {
      const u = [];
      for (const [k, v] of Object.entries(value)) {
        if (k) {
          u.push({
            name: k,
            value: v,
            checked: false
          });
        }
      }
      ar.push({ name: key, value: u });
    }
    return ar;
  }

  public groupBy(list, prop) {
    return list.reduce((groupped, item) => {
      const key = item[prop];
      delete item[prop];
      if (groupped.hasOwnProperty(key)) {
        groupped[key].push(item);
      } else {
        groupped[key] = [item];
      }
      return groupped
    }, {});
  }

  public groupSubKeys(obj, properties, propIndex) {
    const grouppedObj = this.groupBy(obj, properties[propIndex]);
    Object.keys(grouppedObj).forEach((key) => {
      if (propIndex < properties.length - 2) {
        grouppedObj[key] = this.groupSubKeys(grouppedObj[key], properties, propIndex + 1);
      } else {
        grouppedObj[key] = grouppedObj[key].map(item => item[properties[propIndex + 1]])
      }
    });
    return grouppedObj;
  }

  public groupByProperties(list, properties) {
    return this.convertObjectToArray(this.groupSubKeys(list, properties, 0));
  }


  public filterPlainArray(array, filters) {
    const filterKeys = Object.keys(filters);
    return array.filter(t => {
      const item = { ...t, ...t.features };
      return filterKeys.every(key => {  // validates all filter criteria
        if (!filters[key].length) return true;// ignores an empty filter
        if (key === 'priceOut') {
          const min = filters[key][0];
          const max = filters[key][1];
          return filters[key].find(filter => item[key] >= min && item[key] <= max);
        }
        else {
          return filters[key].find(filter => this.getValue(filter) === this.getValue(item[key]));
        }
      });
    });
  }

  searchTree(element, field, matchingValue) {
    if (this.isObjectNotArray(element)) {
      if (element[field] === matchingValue) {
        return element;
      } else if (element.children != null) {
        let i;
        let result = null;
        for (i = 0; result == null && i < element.children.length; i++) {
          result = this.searchTree(element.children[i], field, matchingValue);
        }
        return result;
      }
    } else {
      element.forEach(ee => {
        debugger
        return this.searchTree(ee, field, matchingValue);
      });
    }
    return null;
  }


  findObjectByProperty(object, key, val) {
    const _this = this;
    let value;
    Object.keys(object || []).some(function (k) {
      if (k === key) {
        value = object;
        return true;
      } else if (_this.isArray(object[k])) {
        value = object[k].find(v => v[key] === val);
        return value !== undefined;
      } else if (_this.isObjectNotArray(object[k])) {
        value = _this.findObjectByProperty(object[k], key, val);
        return value !== undefined;
      }
    });
    debugger
    return value;
  }

  public showConfirmDelete(title?: string): Observable<Boolean> {
    const modal = this.bsModalService.show(ConfirmationModalComponent, Object.assign({}, { class: 'modal-center' }));
    (<ConfirmationModalComponent>modal.content).showConfirmationModal(
      title ? title : 'Bạn có chắc chắn muốn xoá dữ liệu này không?',
      'fa fa-check-circle'
    );
    return (<ConfirmationModalComponent>modal.content).onClose;
  }


  public showConfirmCheckout(): Observable<Boolean> {
    const modal = this.bsModalService.show(ConfirmationModalComponent, Object.assign({}, { class: 'modal-center' }));
    (<ConfirmationModalComponent>modal.content).showConfirmationModal(
      'Bạn đã chắc chắn muốn đặt hàng cho đơn hàng này?',
      'fa fa-check-circle'
    );
    return (<ConfirmationModalComponent>modal.content).onClose;
  }

  parseOldNewValue(e: any) {
    const obj = { ...e.oldData, ...e.newData };
    Object.keys(obj).forEach(elm => {
      if (!elm) {
        if (typeof (elm) === 'number') { obj.elm = 0; }
        else if (typeof (elm) === 'string') {
          { obj.elm = ''; }
        }
        else if (typeof (elm) === 'boolean') {
          { obj.elm = true; }
        }
        else if (typeof (elm) === 'object') {
          if (Array.isArray(elm)) {
            { obj.elm = []; }
          } else if (Object.prototype.toString.call(elm) === '[object Date]') {
            { obj.elm = new Date(); }
          }
          else {
            obj.elm = {};
          }
        }
      }
    });
    return obj;
  }


  insertUpdateCart(item) {
    if (!item.quantity) item.quantity = 1;
    this.cart.value[item.key] = item;
    this.cart.value[item.key]['total'] = (item.quantity * item.priceOut);
    this.cart.next(this.cart.value);
    this.localCart.cartUpdateItems(this.cart.value);
    this.toastr.success('Sản phẩm đã được cập nhật ở giỏ hàng thành công');
  }

  removeItemFromCart(item) {
    delete this.cart.value[item.key];
    this.cart.next(this.cart.value);
    this.localCart.cartUpdateItems(this.cart.value);
    this.toastr.success('Đã xóa mặt hàng ' + item.title + ' ra khỏi giỏ hàng');
  }


  QuantityUp(item) {
    if (!item.quantity) item.quantity = 0;
    item.quantity = item.quantity + 1;
    this.insertUpdateCart(item);
  }

  QuantityDown(item) {
    if (!item.quantity) item.quantity = 0;
    if (item.quantity > 1) item.quantity = item.quantity - 1;
    this.insertUpdateCart(item);
  }


}

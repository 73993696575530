import { Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  template: `
    <tinymce [formControl]="formControl" [formlyAttributes]="field"></tinymce>
  `
})
export class TinyMceTypeComponent extends FieldType {

  constructor() {
    super();
  }
}

import { Component, OnInit } from '@angular/core';
import { FirebaseApp } from '@angular/fire/';
import { AngularFireDatabase } from '@angular/fire//database';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DestroyableComponent } from '@core/utils/src/components/destroyable/destroyable.component';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import { Observable } from 'rxjs/Observable';
import { takeUntil } from 'rxjs/operators';

declare var FB: any;

@Component({
  selector: 'core-blog-base',
  template: ''
})
export class BlogBaseComponent extends DestroyableComponent implements OnInit {
  public p: any;
  public condition = '';
  public recentposts: Observable<any>;
  public posts: Observable<any>;
  public categories: Observable<any>;
  public location: any;
  public url: any;
  public categoryProducts: any[];
  constructor(
    public af: FirebaseApp,
    public db: AngularFireDatabase,
    public globalService: GlobalService,
    public localCacheService: LocalCacheService,
    public router: Router,
    public title: Title,
    public meta: Meta,

  ) {
    super();
    this.posts = this.localCacheService.getPostsPublishedAsObservable();
    this.recentposts = this.localCacheService.getPostsPublishedAsObservable(3);
    this.categories = this.localCacheService.getLocalDB('categories');

    this.categoryProducts = this.localCacheService.getRootCategoriesWithAllProducts().map(a => ({ ...a, products: a.products.filter(b => b.showHome === true) }));

  }

  ngOnInit(): void {
    this.localCacheService.GroupInfo$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(list => {
        this.title.setTitle('Tin tức - Blog' + ' | ' + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_NGAN_GON) + '');

        this.meta.updateTag({ content: 'Tin tức - Blog ' + ' | ' + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_DAY_DU) }, "name='description'");
      });

    this.location = document.location.origin;
    // this.posts.subscribe((value) => {
    //   for (let i = 0; i < value.length; i++) {
    //     FB.XFBML.parse(document.getElementById('fbsharebuttonID' + i));
    //   }
    // })

  }


  onPageChange(page: number) {
    this.p = page;
    window.scrollTo(0, 0);
  }

  // SerachTravelArticles(): void {
  //   if (this.condition) {
  //     // this.posts = this.db.list('/posts', ref => ref.orderByChild('title').equalTo(this.condition).limitToLast(20)).valueChanges();
  //     this.posts = of(this.localCacheService.localDataFull['posts'].value.filter(a => a.title === this.condition).slice(0, 20));
  //     this.posts.subscribe((data) => {
  //       if (data.length === 0) {
  //         document.getElementById("searchresult").innerHTML = '<h2>Không tìm thấy bài viết nào...</h2>';
  //       } else {
  //         document.getElementById("searchresult").innerHTML = '<h2>Kết quả tìm kiếm...</h2><hr>';
  //       }
  //     });
  //   } else {
  //     document.getElementById("searchresult").innerHTML = ''
  //     this.posts = this.db.list('/posts', ref => ref.orderByChild('published').equalTo(true).limitToLast(20000)).valueChanges();
  //   }

  // }

  emitChangeValue(value: any) {
    if (value.length > 0) {
      this.router.navigateByUrl('/product', { state: { data: value } });
    }
  }
}

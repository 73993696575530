import { Component } from '@angular/core';
import { OrderBaseComponent } from '@core/base-frontend/src/order-base/order-base.component';

@Component({
  selector: 'orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent extends OrderBaseComponent {

}
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { FieldArrayType } from '@ngx-formly/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'core-admin-repeat-day-type',
  templateUrl: './repeat-day-type.component.html',
  styleUrls: ['./repeat-day-type.component.scss']
})
export class RepeatDayTypeComponent extends FieldArrayType implements OnInit {
  selectedOption: any;
  db: any;
  pageKey: string;
  router: any;
  @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent;

  constructor(
    private toastr: ToastrService,
    private modalService: BsModalService,
    public globalService: GlobalService
  ) {
    super();
  }

  deletedId: any;
  modalRef: BsModalRef;
  ngOnInit() {
  }

  openModal(template: TemplateRef<any>, id) {
    this.deletedId = id;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm modal-dialog-centered' });
  }


  removeTabHandler(id) {
    // const a = this.globalService.showConfirmDelete()
    //   .subscribe(result => {
    //     a.unsubscribe();
    //     if (result) {
    this.remove(id);
    if (id > 0) {
      this.staticTabs.tabs[id - 1].active = true;
    }
    // }
    // });

  }


}

import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire//auth';
import { AngularFireDatabase } from '@angular/fire//database';
import { FormGroup } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DestroyableComponent } from '@core/utils/src/components/destroyable/destroyable.component';
import { CartService } from '@core/utils/src/services/common/cart.service';
import { GlobalService } from '@core/utils/src/services/common/global.service';
import { LocalCacheService } from '@core/utils/src/services/common/local-cache.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/Observable';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'core-cart-list-base',
  template: ''
})
export class CartListBaseComponent extends DestroyableComponent implements OnInit {
  globalCart: any;
  cartArray: any;
  cartTotal: Number;
  user: Observable<firebase.User>;
  currentShopper: any;
  LoggedIn = false;
  MustLogin = false;
  showCheckout = true;
  relatedProducts: any[];
  form = new FormGroup({});
  model = {};
  fields: FormlyFieldConfig[] = [
    {
      type: 'input',
      key: 'customerName',
      templateOptions: {
        label: 'Tên người mua hàng',
        required: true,
      }
    },
    {
      type: 'input',
      key: 'Email',
      templateOptions: {
        label: 'Email',
      }
    },
    {
      type: 'input',
      key: 'Phone',
      templateOptions: {
        label: 'SĐT',
        required: true,
      }
    },
    {
      type: 'textarea',
      key: 'Address',
      templateOptions: {
        label: 'Địa chỉ thanh toán',
        required: true,
        rows: 5
      }
    },
    {
      className: 'checkbox-same-address',
      type: 'checkbox',
      key: 'sameAddress',
      defaultValue: true,
      templateOptions: {
        label: 'Địa chỉ nhận hàng trùng với địa chỉ thanh toán'
      }
    },
    {
      type: 'input',
      key: 'customerNameReceipt',
      templateOptions: {
        label: 'Tên người nhận hàng',
        required: true,
      },
      hideExpression: 'model.sameAddress === true'
    },
    {
      type: 'input',
      key: 'EmailReceipt',
      templateOptions: {
        label: 'Email',
      },
      hideExpression: 'model.sameAddress === true'
    },
    {
      type: 'input',
      key: 'PhoneReceipt',
      templateOptions: {
        label: 'SĐT',
        required: true,
      },
      hideExpression: 'model.sameAddress === true'
    },
    {
      type: 'textarea',
      key: 'AddressReceipt',
      templateOptions: {
        label: 'Địa chỉ nhận hàng',
        required: true,
        rows: 5
      },
      hideExpression: 'model.sameAddress === true'
    }

  ]


  constructor(public db: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    public router: Router,
    public globalService: GlobalService,
    public localCacheService: LocalCacheService,
    public localCart: CartService,
    private toastr: ToastrService,
    private title: Title,
    private meta: Meta) {
    super();
    this.user = afAuth.authState;
    this.cartArray = [];
    this.cartTotal = 0;

    this.user
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(currentUser => {
        if (currentUser) {
          this.currentShopper = currentUser;
          this.LoggedIn = true;
          this.showCheckout = ((this.MustLogin && this.LoggedIn) || !this.MustLogin) && (this.cartArray && this.cartArray.length > 0) ? true : false
          this.model['customerName'] = currentUser.displayName;
          this.model['Email'] = currentUser.email;
          this.model['Phone'] = currentUser.phoneNumber;
        }
      });

    if (this.localCacheService.getCommonInfoDisplay(this.localCacheService.KHACH_LE_MUA_HANG) + '' === 'Không') {
      this.MustLogin = true;
    }


    globalService.cart.subscribe((cart) => {
      this.cartArray = [];
      this.cartTotal = 0;
      this.globalCart = cart;
      if (this.globalCart) {
        this.cartArray = (<any>Object).values(this.globalCart);
        if (this.cartArray && this.cartArray.length > 0)
          this.relatedProducts = this.localCacheService.getProductsRelated(this.cartArray[0].key, 4);

        for (let i = 0; i < this.cartArray.length; i++) {
          this.cartTotal += this.cartArray[i].total;
        }
      }
    });
  }

  ngOnInit() {

    this.localCacheService.GroupInfo$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(list => {
        this.title.setTitle('Giỏ hàng (cart)' + ' | ' + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_NGAN_GON) + '');

        this.meta.updateTag({ content: 'Giỏ hàng (cart)' + this.localCacheService.FilterGroupInfo(list, '', this.localCacheService.TEN_CONG_TY_DAY_DU) }, "name='description'");
      });


  }

  GoToCheckout() {
    if (this.form.valid) {
      if (this.MustLogin && !this.LoggedIn) {
        this.router.navigate(['login'], { queryParams: { returnUrl: '/order' } });
      }
      else {
        this.globalService.showConfirmCheckout()
          .pipe(takeUntil(this.unsubscribeAll))
          .subscribe(result => {
            const datenow = (new Date()).toISOString()
            if (result) {
              const order = {
                items: { ...this.globalCart },
                shipping: { ...this.model },
                uid: '',
                total: this.cartTotal,
                date: datenow,
                status: 'Đã đặt hàng'
              };
              if (this.currentShopper) {
                order.uid = this.currentShopper.uid;
              }

              this.db.list('/orders').push(order).then((item) => {
                this.localCart.clearAll();
                if (this.currentShopper) {
                  this.db.object('/users/' + this.currentShopper.uid + '/address').set(this.model['Address']);
                  this.db.object('/users/' + this.currentShopper.uid + '/phoneNumber').set(this.model['Phone']);
                  if (this.currentShopper.uid) this.db.object('/users/' + this.currentShopper.uid + '/cart').remove();
                  this.db.object('/users/' + this.currentShopper.uid + '/orders/' + item.key).set(datenow);
                }
                this.localCacheService.increaseDB('orders');
                this.localCacheService.increaseDB('users');
                setTimeout(() => {
                  this.router.navigateByUrl('order/mine/' + item.key);
                }, 1000);
              });
            }
          });
      }
    }
    else {
      this.toastr.error("Bạn chưa điền đầy đủ thông tin yêu cầu");
      const _this = this;
      Object.keys(this.form.controls).forEach(function (key, index) {
        _this.form.controls[key].markAsTouched();
      });
    }
  }

}

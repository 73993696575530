import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'randomslice'
})
export class RandomSlicePipe implements PipeTransform {
  transform(value: any[], args: number): any[] {
    const q = [];
    if (value) {
      value = value.sort((a, b) => (new Date(a.date) > new Date(b.date)) ? 1 : -1);
      const n = args ? args : 0;
      for (let i = 0; i < n; i++) {
        // const fff = value[this.getRandomInt(0, value.length)];
        // if (fff && !q.some(x => x.key === fff.key)) {
        //   q.push(fff);
        // } else {
        //   //i--;
        // }

        const fff = value[i];
        if (fff) q.push(fff);
      }
      return q;
    } else {
      return []
    }
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

}
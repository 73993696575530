import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'core-admin-ck-editor-type',
  templateUrl: './ck-editor-type.component.html',
  styleUrls: ['./ck-editor-type.component.scss']
})
export class CkEditorTypeComponent extends FieldType implements OnInit, AfterViewChecked {
  @ViewChild('editor', { static: false }) ckEditor: CKEditorComponent;

  public Editor = DecoupledEditor;
  constructor() { super(); }

  ngOnInit() {


  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }
  ngAfterViewChecked() {

  }

}

import { AfterContentInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { RemoveWrapperDirective } from './remove-wrapper.directive';

@Component({
  selector: 'core-admin-formly-wrapper-form-field',
  template: `
          <div class="{{ to.label ? 'form-group' : '' }}" remove-wrapper>
          <label class="form-control-label control-label" *ngIf="!to.hideLabel">
            {{ to.label }}
            <span class="required-mark">
              {{ to.label && to.required ? ' *' : '' }}
            </span>
          </label>

          <ng-template #fieldComponent></ng-template>

          <div *ngIf="!to.hideError">
            <formly-validation-message #validationMessages [field]="field" [style.display]="'none'">
            </formly-validation-message>

            <div class="error-bottom" *ngIf="
                validationMessages &&
                formControl &&
                formControl.touched &&
                formControl.invalid
              ">
              {{ validationMessages.errorMessage }}
            </div>
          </div>
        </div>
  `,
  styles: [
    `
      .required-mark {
        color: #f64f45;
        font-size: 13px;
      }

      .error-bottom {
        color: #f64f45;
        font-size: 12px;
        padding-top: 5px;
      }

      ::ng-deep formly-group,
      ::ng-deep vt88-core-date-from-to-formly-type {
        display: block !important;
      }

      ::ng-deep formly-field {
        display: inline-block;
        vertical-align: bottom;
        width: 100%;
      }
      ::ng-deep .error-bottom {
        position: absolute;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 90%;
      }

      ::ng-deep .error-bottom:hover,
      ::ng-deep .error-bottom-focused {
        position: absolute;
        white-space: pre-wrap;
        background-color: #f64f45;
        color: white !important;
        z-index: 1000;
        width: 90%;
        padding: 5px;
      }

      ::ng-deep .form-group {
        margin-bottom: 1.7rem;
      }

      @media (orientation: portrait) {
        ::ng-deep .form-group {
          border-radius: 5px 5px 0px 0px;
          background: #f2f2f2;
        }
        ::ng-deep label.form-control-label {
          margin-left: 10px;
          margin-bottom: 0;
          font-size: 11px;
        }
      }
    `
  ]
})
export class FormlyFieldWrapperComponent extends FieldWrapper
  implements AfterContentInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true })
  fieldComponent!: ViewContainerRef;
  @ViewChild(RemoveWrapperDirective, { static: true }) directive;

  ngAfterContentInit() {
    if (this.directive) {
      this.directive.removeParentTag();
    }
  }
}
